import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TradeSharedService {
  public selectedEtrType = new Subject<any>();
  constructor() {}

  setEtrType(EtrType: any) {
    this.selectedEtrType.next(EtrType);
  }

  getEtrType() {
    return this.selectedEtrType.asObservable();
  }
}
