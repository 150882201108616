import { Component, HostListener} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { HttpService } from 'src/app/services/http.service';
import { DatePipe } from '@angular/common';
import {
  EditInvestmentsService
} from '../../investments/edit-investments.service'

@Component({
  selector: 'app-checkbank',
  templateUrl: './masterbank.component.html',
  styleUrls: ['./masterbank.component.scss']
})
export class MasterbankComponent {
    // Variable to control the visibility of the loader
    isLoading: boolean = true;
    //DefaultUrl: string='http://localhost:55709/MasterBanks.aspx?height=';
    DefaultUrl: string='https://reports.credebt.exchange/MasterBanks.aspx?height=';

     bank_codes: any ;
     selectedBankCode: any = null;
     accounts: any ;
     unique:any;
     selectedAccount: any  = null;
     searchText: any;
     selectedClutch:any  = "All";
     flag:boolean= false;

    iframeSource: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.DefaultUrl);
    constructor(private sanitizer: DomSanitizer, public editInvestmentsService: EditInvestmentsService,  private http: HttpService,private datePipe: DatePipe)    
    {
     this.loadDefaultDateRange();
     }   
  onIframeLoad(event: Event): void {     
    this.isLoading = false;     
    const iframe = event.target as HTMLIFrameElement;    
     if (iframe.contentWindow) {  
      iframe.height= this.innerheight+"px";    
      iframe.width= iframe.offsetWidth+"px"; 
      }
  }
  showData(type: string) {
     if(type == 'All') {  
      this.currentFilter = type
      this.loadBankCode(); 
      this.loadAccounts();     
      this.selectedFinancialYear = '';
      this.dateRange = '';
      this.last_daterange="";
      this.selectedMonth='';
      this.selectedAccount="";
      this.selectedBankCode="";
      this.selectedClutch="All";
      this.searchText="";
    
      this.QueryString_Year="";   
      this.QueryString_Month="";  
      this.QueryString_Ac_No="";
      this.QueryString_Bank_Code="";
      this.QueryString_Clutch="";
      this.QueryString_Date_Range="";
      this.QueryString_SearchInAllField=""; 
      this.updateIframeUrl();
      this.loadAllDateRange();

    }else if(type == 'Date') {
      this.currentFilter = type
      this.dateRange=this.last_daterange;
      //alert("date");
    }else if(type == 'Year') {
      this.currentFilter = type
      //alert("year");
    }
    else if(type == 'Month') {    
      this.currentFilter = type
      //alert("year");
    }
  }
  financialChange() {
    this.QueryString_Month=""
    this.QueryString_Date_Range="";
    this.last_daterange="";
    this.dateRange="";
    this.selectedMonth="";
    if(this.selectedFinancialYear != "") {
     this.QueryString_Year="&Fy="+this.selectedFinancialYear;  
    }
    else
    {
      this.QueryString_Year="";
    }
    this.updateIframeUrl();   
  }
  ChangePerPageRecordSelection() {
    this.QueryString_RecordPerPage="&Totalrecordperpage="+this.selectedRecordPerPage;       
    this.updateIframeUrl();
  }
  RefreshPage() {  
    this.updateIframeUrl();   
    this.toggleFlag();
  }
  ClutchChange() { 
    if(this.selectedClutch != "") {
     this.QueryString_Clutch="&Clutch="+this.selectedClutch;  
    }
    else
    {
      this.QueryString_Clutch="";
    }
    this.updateIframeUrl();   
  }
  QueryString_RecordPerPage:any="&Totalrecordperpage=50";
  MonthChange() {
    this.QueryString_Year="";
    this.selectedFinancialYear="";
    this.QueryString_Date_Range="";
    this.last_daterange="";
    this.dateRange="";
    if(this.selectedMonth != "") {
     this.QueryString_Month="&Month="+this.selectedMonth;  
    }
    else
    {
      this.QueryString_Month="";
    }
    this.updateIframeUrl();   
  }
  updateIframeUrl(){   
    this.isLoading = true;  
    const Headerheight:any= this.GetHeaderHeight();   
    const Iframeheight:any= (window.innerHeight-Headerheight);
    this.innerheight=Iframeheight;
    this.innerWidth = window.innerWidth;   
    var QueryString=this.QueryString_RecordPerPage+this.QueryString_Ac_No+this.QueryString_Bank_Code+this.QueryString_Date_Range+this.QueryString_Year+this.QueryString_SearchInAllField+this.QueryString_Month+this.QueryString_Clutch; 
    this.iframeSource= this.sanitizer.bypassSecurityTrustResourceUrl(this.DefaultUrl+((Iframeheight-50)+"px")+QueryString);  
  }
  public innerheight: any;
  public innerWidth: any;  
  dateRange: any = null;
  bsValue = new Date();
  bsRangeValue: Date[] | undefined;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  currentFilter: any = 'Date';
  financialList: any;
  QueryString: any;
  selectedFinancialYear: any = "";  
  selectedMonth: any ="";  
  SearchTextLengh:any=0;
  MonthList :any;
  currency_list:any=[];
  ClutchList:any;
  selectedRecordPerPage: any = "50";  
  onDateRangeChange(event: any) {
    this.QueryString_Month="";
    this.QueryString_Year="";
    this.selectedMonth="";
    this.selectedFinancialYear="";    
    if (event && event[0] !== undefined) {
      this.last_daterange=moment(event[0]).format('YYYY-MM-DD')+" - "+moment(event[1]).format('YYYY-MM-DD');
      this.QueryString_Date_Range="&fdate="+moment(event[0]).format('YYYY-MM-DD')+"&tdate="+moment(event[1]).format('YYYY-MM-DD');   
      this.updateIframeUrl();   
    }else {
    }
  }
  
ngOnInit() {
  this.SetIframe(); 
  this.loadBankCode();
  this.loadAccounts(); 
  this.financialList = this.editInvestmentsService.Year ;
  //this.MonthList = this.editInvestmentsService.Month;
  this.ClutchList= this.editInvestmentsService.Clutch;
  this.loadAllDateRange();
  this.currency_list = [
    {
      name:"EUR",
      code:"EUR"
    },
    {
      name:"GBP",
      code:"GBP"
    },
    {
      name:"USD",
      code:"USD"
    }
  ];
  
  }

  ResetPage(){  
    this.searchText="";
    this.currentFilter ="Date";
    this.selectedClutch  = "All"
    this.selectedBankCode=null;
    this.loadBankCode();   
    this.loadAccounts();  
    this.financialList = this.editInvestmentsService.Year ;
    //this.MonthList = this.editInvestmentsService.Month;
    this.ClutchList= this.editInvestmentsService.Clutch;
    this.toggleFlag();
    this.SetIframe(); 
    this.loadAllDateRange();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {     
    this.updateIframeUrl();  
    // this.SetIframeWithDate();     
  } 
  GetHeaderHeight() {
    return document.getElementById("header-row")?.clientHeight;
  }
  SetIframe()
  {
    this.isLoading = true;  
    const Headerheight:any= this.GetHeaderHeight();   
    const Iframeheight:any= (window.innerHeight-Headerheight);
    this.innerheight=Iframeheight;
    this.innerWidth = window.innerWidth;    
    this.iframeSource= this.sanitizer.bypassSecurityTrustResourceUrl(this.DefaultUrl+((Iframeheight-50)+"px")+this.QueryString_RecordPerPage +this.QueryString_Date_Range);
  }

  loadBankCode(){
    this.http.getData("bank/list").subscribe((response: any) => {
      //console.log(response.data);
      this.bank_codes = response.data;
    },
    (error) => {
      console.error('Error:', error);
    })
  }
  //

  toggleFlag (){
    if(!this.flag){
      this.flag =  true;
    }else{
      this.flag = false;
    }
  }

  loadAllDateRange(){
    this.http.getData("bank/date/range").subscribe((response: any) => {
     this.dateRange = response.data[0].minDate + " -  " +  response.data[0].maxDate;
     this.MonthList = response.months;
    },
    (error) => {
      console.error('Error:', error);
    })
  }

  filter(bcode:any){
    return   this.accounts.filter((it:any) =>  it.bank_code!=null && it.bank_code.toLowerCase()== bcode.toLowerCase());
  }

  loadAccounts(){

    this.http.postData("bank/account/list",{bank_code:this.selectedBankCode}).subscribe((response: any) => {
      
      const unique = [...new Set(response.data.map((item:any) => {
       // return item.bank_code 
       if(item.bank_code!=null && item.bank_code!=''){
        const words =  item.bank_code.split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
        //item.bank_code = words.join(" ");
        console.log( words.join(" "));
        return words.join(" ");
      }
      }
        
       ))];
      this.accounts =response.data;
      this.unique = unique.filter(function(element) {
        return element != null;
      });
    },
    (error) => {
      console.error('Error:', error);
    })
  }
  last_daterange:any;
  loadDefaultDateRange(){     
    var cdate= new Date();
    var fodate=new Date();
    fodate.setFullYear(fodate.getFullYear() - 1);
    var cdate_formate=this.datePipe.transform(  cdate,'yyyy-MM-dd');
    var fdate_formate=this.datePipe.transform(  fodate,'yyyy-MM-dd');
    this.QueryString_Date_Range="&fdate="+fdate_formate+"&tdate="+cdate_formate; 
    this.last_daterange=fdate_formate + " - " +cdate_formate;
    this.dateRange =this.last_daterange;
  }

  currencyChange(){

  }

  codeChange(){
    console.log(this.selectedBankCode);
    this.loadAccounts();
    var qs:any="";
    if (this.selectedBankCode!=null && this.selectedBankCode!="null")
    {
      qs="&Bank_Code="+this.selectedBankCode;
    }
    this.QueryString_Bank_Code=qs;  
    this.QueryString_Ac_No="";
    this.updateIframeUrl(); 
  }
  accountChange(){
      var qs:any="";
      if (this.selectedAccount!=null && this.selectedAccount!="null")
      {
        qs="&Ac_No="+this.selectedAccount;
      }
      this.QueryString_Ac_No=qs;   
      this.updateIframeUrl();  
  }
  QueryString_Bank_Code:any="";
  QueryString_Ac_No:any="";
  QueryString_SearchInAllField:any="";
  QueryString_Year:any="";
  QueryString_Date_Range:any="";
  QueryString_Month :any="";
  QueryString_Clutch:any="";
  search (){   
      if(this.searchText.length==3){  
        this.SearchTextLengh=3;
        this.QueryString_SearchInAllField="&SearchInAllField="+this.searchText;  
        this.updateIframeUrl(); 
      } 
      else
      {
       if( this.SearchTextLengh==3 && this.searchText.length==0)
       {
        this.SearchTextLengh=0;
        this.QueryString_SearchInAllField="&SearchInAllField="+this.searchText;  
        this.updateIframeUrl(); 
       }
      }    
  }
}
