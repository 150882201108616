<!-- example.component.html -->

<div class="iframe-container">
    <div class="card mb-0 common-header" id="header-row">
        <div class="card-body header-title">          
          <div class="row"> 
        <div class="col-md-2">
          <p class="card-title ps-1">Master Bank</p>
        </div>            
              <div class="col-md-10 text-sm-end d-flex justify-content-end custom-invest-filter">  
                <div class="me-2 d-flex align-items-center daterange_sec select_bank_filter">
                  <label class="me-1">Currency:</label>
                  <select class="custom-filter-select border-radius-100 select_currency" id="pageSize"  (change)="currencyChange()" [(ngModel)]="selectedBankCode"  placeholder="Select bank code">
                    <option value="null" selected="selected" disabled>Select Currency</option>
                    <option *ngFor="let list of currency_list" [value]="list.code">{{ list.name }}</option>
                  </select>
                </div>

                <div class="me-2 d-flex align-items-center daterange_sec select_bank_filter">
                  <label class="me-1">Bank:</label>
                  <select class="custom-filter-select border-radius-100" id="pageSize"  (change)="codeChange()" [(ngModel)]="selectedBankCode"  placeholder="Select bank code">
                    <option value="null" selected="selected">Select Bank</option>
                    <option *ngFor="let list of bank_codes" [value]="list.bank_code">{{ list.bank_code }}</option>
                  </select>
                </div>

                <div class="me-2 d-flex align-items-center daterange_sec select_account_filter">
                  <label class="me-1">Account:</label>
                  <select class="custom-filter-select border-radius-100" id="pageSize"  (change)="accountChange()" [(ngModel)]="selectedAccount"  placeholder="Select account"> 
                    <option value="null" selected="selected">Select Account</option>
                    <optgroup *ngFor="let val of unique" [label]="val">
                      <option  *ngFor="let account of filter(val)"  [value]="account.account_no">{{ account.account_no }}</option>
                    </optgroup>
                  </select>
                </div>
                <div class="me-2 d-flex align-items-center daterange_sec select_clutch_filter">
                  <label class="me-1">Clutch:</label>
                  <select class="custom-filter-select border-radius-100"  (change)="ClutchChange()" [(ngModel)]="selectedClutch" >
                    <option *ngFor="let ClutchList of ClutchList" [value]="ClutchList.id">{{ ClutchList.name }}</option>
                  </select>
                </div>
                <div class="me-2 d-flex align-items-center daterange_sec">
                  <input type="text"  placeholder="Search"  class="me-0 btn-light text-start custom-tab-button" (keyup)="search()" [(ngModel)]="searchText"/>
                </div>

                <div class="me-2 d-flex align-items-center daterange_sec">
                  <!-- <input type="text" #banknoFilter placeholder="Bank No"  (input)="updateIframe(banknoFilter.value)"/>
                  <input type="text" #accountnoFilter placeholder="Bank No"  (input)="updateIframe(accountnoFilter.value)"/> -->
                  <button type="button" class="btn btn-light custom-tab-button" (click)="showData('All')" [ngClass]="{'active': currentFilter == 'All'}">All</button>
                  <button type="button" class="btn btn-light custom-tab-button" (click)="showData('Month')" [ngClass]="{'active': currentFilter == 'Month'}">Month</button>                     
                  <button type="button" class="btn btn-light custom-tab-button" (click)="showData('Year')" [ngClass]="{'active': currentFilter == 'Year'}">Year</button>
                  <button type="button" class="btn btn-light custom-tab-button" (click)="showData('Date')" [ngClass]="{'active': currentFilter == 'Date'}">Range</button>  
                  
                  <div class="date-range d-flex align-items-center single_select_filter"  *ngIf="currentFilter === 'Month'" >
                    <label class="me-1">Month:</label> 
                    <select class="custom-filter-select border-radius-100" id="MonthList"  (change)="MonthChange()" [(ngModel)]="selectedMonth"> 
                      <option value="" selected="selected" disabled>Select Month</option>
                      <option *ngFor="let list of MonthList" [value]="list.date">{{ list.date }}</option>
                    </select>
                  </div>
                  <div class="date-range d-flex align-items-center m-0 single_select_filter"  *ngIf="currentFilter === 'Year'" >
                    <label class="me-1">Year:</label> 
                    <select class="custom-filter-select border-radius-100" id="pageSize"  (change)="financialChange()" [(ngModel)]="selectedFinancialYear"> 
                      <option *ngFor="let list of financialList" [value]="list.id">{{ list.name }}</option>
                    </select>
                  </div>
                  <div class="d-flex align-items-center single_select_filter" *ngIf="currentFilter !== 'Year' && currentFilter !== 'Month'">
                   <label class="me-1">Date:</label> 
                  <input
                  type="search"
                  class="form-control from-daterange mt-0"
                  placeholder="Start Date - End Date"
                  bsDaterangepicker
                  [bsConfig]="{ rangeInputFormat : 'YYYY-MM-DD', dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false }" 
                  (ngModelChange)="onDateRangeChange($event)"  
                  [(ngModel)]="dateRange"
                  autocomplete="off"
                />   
                </div></div>                             
                  <select  class="selectbox-per-page custom-invest-select border-radius-100 ng-pristine ng-valid ng-touched filter-pagesize" (change)="ChangePerPageRecordSelection()" [(ngModel)]="selectedRecordPerPage">
                    <option  value="50" class="ng-star-inserted">50 per page</option>
                    <option  value="100" class="ng-star-inserted">100 per page</option>
                    <option value="250" class="ng-star-inserted">250 per page</option>
                    <option  value="500" class="ng-star-inserted">500 per page</option>
                    <option value="1000" class="ng-star-inserted">1,000 per page</option>
                    <!-- <option value="2500" class="ng-star-inserted">2,500 per page</option>
                    <option value="5000" class="ng-star-inserted">5,000 per page</option>
                    <option value="10000" class="ng-star-inserted">10,000 per page</option> -->
                  </select>   
                <!-- <button type="button" class="btn btn-light custom-invest-button" (click)="RefreshPage()" ><img src="./assets/images/icon/replay.svg" width="16px"></button> -->
                <!-- <button type="button" class="btn btn-light custom-invest-button"><img src="./assets/images/icon/fullscreen.svg"></button>                -->

                <div class="dropdown reload_dropdown">
                  <button type="button" (click)="toggleFlag()" class="btn custom-invest-button"  ><img src="./assets/images/icon/replay.svg" width="16px"></button>
                  <div class="dropdown-content {{flag ? 'show' : 'hide'}}" >
                      <li title="Reset Existing Data" (click)="ResetPage()">Reset</li>
                      <li title="Reload Entire Report" (click)="RefreshPage()">Reload</li>
                  </div>
                </div>
              </div>      
            </div>
        </div>   
      </div>
     
      <div id="iframsaction">
        <div *ngIf="isLoading" class="loader_main">
          <div class="loader">Loading...</div>
        </div>
        <iframe [src]="iframeSource"  (load)="onIframeLoad($event)">      
        </iframe>
      </div>   
</div>



