import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <select (change)="calculateTotal($event)" [(ngModel)]="value">
      <option *ngFor="let item of bottomRowConfig" [value]="item.id">
        {{ item.name }}
      </option>
    </select>
  `,
})
export class CustomPinnedRowRenderer implements ICellRendererAngularComp {
  public params: any;
  bottomRowConfig: any[] = [];
  column!: string;
  value: string = 'sum';

  agInit(params: any): void {
    this.params = params;
    this.column = this.params.column;
    this.bottomRowConfig = params.values;
    this.value = params.valueFormatted;
  }

  refresh(params: any) {
    this.params = params;
    // As we have updated the params we return true to let AG Grid know we have handled the refresh.
    // So AG Grid will not recreate the cell renderer from scratch.
    return true;
  }

  calculateTotal($event: any) {
    this.params.setTotal({ value: $event.target.value, column: this.column });
  }
}
