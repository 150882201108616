import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { FxrateQueryParamsInterface } from '../interfaces/fxrate-query-params.interface';
import { Observable } from 'rxjs/internal/Observable';
import { FxrateInterfce, FxrateResponseInterface } from '../interfaces/fxrate-response.interface';
import { Summary } from 'src/app/shared/interfaces/base-response-interface';

@Injectable({ providedIn: 'root' })
export class FxrateHttpService extends HttpService {
  getFxRateData(params: Partial<FxrateQueryParamsInterface>): Observable<FxrateResponseInterface> {
    return this.http.get<FxrateResponseInterface>(`${this.baseUrl}fx-rates?`, {
      params: this.queryCleaner(params),
    });
  }

  getFxRateById(id: number): Observable<FxrateInterfce> {
    return this.http.get<FxrateInterfce>(`${this.baseUrl}fx-rates/${id}`);
  }

  getFxRateSummaryData(params: Partial<FxrateQueryParamsInterface>): Observable<Summary> {
    return this.http.get<Summary>(`${this.baseUrl}fx-rates/summary`, {
      params: this.queryCleaner(params),
    });
  }
}
