import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FlashMessageType } from '../../helpers/flash-message.helper';

/*
* Component example.
* Please call mbz-flash-message with this params
* [text] - displayed text
* [messageType] - this is the message types . we have this types. Error,Warning,Success,Info,Muted
*                In order to set status please import MessageType from this component and use it.
* [(status)]- this will show or hide the flash message.
*
* <mbz-flash-message
    [text]="textVar"
    [messageType]="messageType.Success"
    [(status)]="statusVar"
    >

  </mbz-flash-message>
*
*/

@Component({
  selector: 'app-flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss'],
})
export class FlashMessageComponent {
  @Input() messageType = FlashMessageType.Info;
  @Input() text: string;
  @Input() status: boolean;
  @Input() width = 'fit-content';

  @Output() statusChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Handle flash message close button click.
   */
  closeMessage(): void {
    this.status = false;
    this.statusChange.emit(this.status);
  }
}
