export function isScientificNotation(number: number): boolean {
  // Convert the number to a string
  const numberString = number.toString();

  // Define a regular expression pattern to match scientific notation
  const scientificNotationPattern = /^[+-]?\d+(\.\d+)?[eE][+-]?\d+$/;

  // Test if the number matches the pattern
  return scientificNotationPattern.test(numberString);
}
