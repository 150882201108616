<div class="row">
    <div class="col-lg-12">
        <div class="w-100">
            <form *ngIf="fxRateForm" [formGroup]="fxRateForm">
                <div class="row d-flex">
                    <div class="col-md-9 d-flex justify-content-start">
                        <div class="mt-3">
                            <label for="date" class="form-title">
                                <span class="required">*</span> Date
                                <span class="tooltip-ng" placement="bottom" ngbTooltip="{{ tooltipData?.date }}">
                                        <i class="bi bi-question-circle"></i>
                                    </span>
                            </label>

                            <div class="input-group" style="width: 115px">
                                <input
                                    id="date"
                                    type="text"
                                    class="form-control"
                                    placeholder="YYYY-MM-DD"
                                    name="name"
                                    formControlName="date"
                                    ngbDatepicker
                                    #d2="ngbDatepicker"
                                    [maxDate]="maxDate"
                                    [readonly]="true"
                                />
                                <button
                                    class="btn btn-outline-secondary btn-calendar-icon"
                                    (click)="d2.toggle()"
                                    type="button"
                                >
                                    <img
                                        src="../../../../../assets/images/icon/calendar-icon.svg"
                                    />
                                </button>
                            </div>
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['date']" />
                    </div>
                </div>
                <div class="row d-flex">
                    <div style="width: 80px;">
                        <div class="mt-3">
                            <label for="demand" class="form-title"> Demand
                                <span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData?.obfr }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                            <div class="input-container">
                                <input
                                    id="demand"
                                    class="form-control text-right sd-width inputNumberText"
                                    formControlName="demand"
                                    maxlength="5"
                                    (input)="limitInputLength($event.target)"
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['demand']" />
                    </div>
                    <div style="width: 80px;">
                        <div class="mt-3">
                            <label for="fixed" class="form-title"> Fixed
                                <span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData?.obfr }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                            <div class="input-container">
                                <input
                                    id="fixed"
                                    class="form-control text-right sd-width inputNumberText"
                                    formControlName="fixed"
                                    maxlength="5"
                                    (input)="limitInputLength($event.target)"
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['fixed']" />
                    </div>
                    <div style="width: 80px;">
                        <div class="mt-3">
                            <label for="term" class="form-title"> Term
                                <span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData?.obfr }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                            <div class="input-container">
                                <input
                                    id="term"
                                    class="form-control text-right sd-width inputNumberText"
                                    formControlName="term"
                                    maxlength="5"
                                    (input)="limitInputLength($event.target)"
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['term']" />
                    </div>
                    <div style="width: 93px;">
                        <div class="mt-3">
                            <label for="buyRate" class="form-title"
                            ><span class="required">*</span> Buy Rate
                                <span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData?.buyRate }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                            <div class="input-container">
                                <input
                                    id="buyRate"
                                    class="form-control text-right ft-width inputNumberText"
                                    formControlName="buyRate"
                                    maxlength="5"
                                    (input)="limitInputLength($event.target)"
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['buyRate']" />
                    </div>
                    <div style="width: 70px;">
                        <div class="mt-3">
                            <label for="gbp" class="form-title"> GBP </label>
                            <input
                                [onlyNumber]="false"
                                id="gbp"
                                type="text"
                                class="form-control text-right ft-width inputNumberText"
                                formControlName="gbp"
                                maxlength="7"
                                (input)="limitInputLength($event.target)"
                            />
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['gbp']" />
                    </div>
                    <div style="width: 70px;">
                        <div class="mt-3">
                            <label for="usd" class="form-title">USD </label>
                            <input
                                id="usd"
                                [onlyNumber]="false"
                                type="text"
                                class="form-control text-right ft-width inputNumberText"
                                formControlName="usd"
                                maxlength="7"
                                (input)="limitInputLength($event.target)"
                            />
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['usd']" />
                    </div>
                    <div style="width: 100px;">
                        <div class="mt-3">
                            <label for="eonia" class="form-title"> EURIBOR
                                <span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData?.eonia }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                            <div class="input-container">
                                <input
                                    id="eonia"
                                    class="form-control text-right sd-width inputNumberText"
                                    formControlName="eonia"
                                    maxlength="5"
                                    (input)="limitInputLength($event.target)"
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['eonia']" />
                    </div>
                    <div style="width: 100px;">
                        <div class="mt-3">
                            <label for="libor" class="form-title"> LIBOR
                                <span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData?.libor }}">
                                        <i class="bi bi-question-circle"></i>
                                    </span>
                            </label>
                            <div class="input-container">
                                <input
                                    id="libor"
                                    class="form-control text-right sd-width inputNumberText"
                                    formControlName="libor"
                                    maxlength="5"
                                    (input)="limitInputLength($event.target)"
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['libor']" />
                    </div>
                    <div style="width: 100px;">
                        <div class="mt-3">
                            <label for="obfr" class="form-title"> SOFR
                                <span
                                    class="tooltip-ng"
                                    placement="bottom"
                                    ngbTooltip="{{ tooltipData?.obfr }}"
                                ><i class="bi bi-question-circle"></i></span
                                ></label>
                            <div class="input-container">
                                <input
                                    id="obfr"
                                    class="form-control text-right sd-width inputNumberText"
                                    formControlName="obfr"
                                    maxlength="5"
                                    (input)="limitInputLength($event.target)"
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <app-form-error-component [control]="fxRateForm.controls['obfr']" />
                    </div>
                </div>
                <div class="row d-flex">
                    <div class="col-12">
                        <hr />
                        <div class="d-flex justify-content-center mt-3">
                            <button [disabled]="fxRateForm.invalid" type="submit"
                                    class="btn btn-light me-3 btn-custom-footer" (click)="onSubmit()">
                                Save
                            </button>
                            <button
                                type="button"
                                class="btn btn-light btn-custom-footer"
                                data-dismiss="modal"
                                (click)="closeModal()"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
