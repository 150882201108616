import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FinancialStatementItemInterface } from 'src/app/financial-statement/common/interfaces/financial-statement-item.Interface';

@Component({
  selector: 'app-html-to-pdf',
  templateUrl: './html-to-pdf.component.html',
  styleUrls: ['./html-to-pdf.component.scss'],
})
export class HtmlToPdfComponent {
  @ViewChild('page1Content', { static: false }) page1Content: ElementRef;
  @ViewChild('page2Content', { static: false }) page2Content: ElementRef;
  pdf_Data: FinancialStatementItemInterface | any;
  logoUrl = '../../../assets/images/logos/logo-investabill-dark.svg';
  stampUrl = '../../../assets/images/logos/logo-stamp-dark.png';
  @Output() pdfGenerated: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() {}

  downloadAsPDF(fileName: string, pdfData: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!pdfData) {
        reject('');
        this.pdfGenerated.emit(false);
        return;
      }
      this.pdf_Data = pdfData;
      setTimeout(() => {
        try {
          const customPageWidth = 199; // A4 width in mm
          const customPageHeight = 90;
          const doc = new jsPDF('l', 'mm', [customPageWidth, customPageHeight]);
          const options = {
            background: 'white',
            scale: 2.9,
          };
          const margin = 0;
          const headerHeight = 7;
          const effectivePageHeight = doc.internal.pageSize.height;
          const renderPage = async (dataElement: HTMLElement, pageNumber: number, totalPages: number) => {
            dataElement.style.width = '1150px';
            const canvas = await html2canvas(dataElement, options);
            const imgWidth = doc.internal.pageSize.width;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const imgData = canvas.toDataURL('image/jpeg', 0.1);
            let position = margin + headerHeight;
            doc.addImage(imgData, 'JPEG', margin, position - 1, imgWidth, Math.min(effectivePageHeight, imgHeight));
            this.addFooter(doc, pageNumber, totalPages);
          };
          const generatePDF = async () => {
            await renderPage(this.page1Content.nativeElement, 1, 2);
            doc.addPage();
            await renderPage(this.page2Content.nativeElement, 2, 2);
          };
          generatePDF()
            .then(() => {
              const pdfBlob = doc.output('blob');
              const downloadLink = document.createElement('a');
              downloadLink.href = URL.createObjectURL(pdfBlob);
              downloadLink.download = `${fileName}.pdf`;
              downloadLink.click();
              window.open(URL.createObjectURL(pdfBlob), '_blank');
              this.pdfGenerated.emit(true);
              resolve();
            })
            .catch((error) => {
              this.pdfGenerated.emit(false);
              reject(error);
            });
        } catch (error) {
          this.pdfGenerated.emit(false);
          reject(error);
        }
      }, 100);
    });
  }

  private addFooter(doc: jsPDF, pageNumber: number, totalPages: number) {
    const margin = 10;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const currentDate = this.pdf_Data?.date;
    doc.setFontSize(10);
    if (pageNumber === 2) {
      const linkURL = 'https://www.investabill.com/faq/#yield';
      const linkWidth = 60;
      const linkHeight = 10;
      const linkX = pageWidth - margin - linkWidth;
      const linkY = pageHeight - margin - linkHeight;
      const linkColor = '#0000FF';
      doc.setFontSize(7);
      doc.setTextColor(linkColor);
      doc.textWithLink(linkURL, linkX - 96.5, linkY + 2.7, { url: linkURL });
      doc.textWithLink('Investabill® Retail – Yield & Costs', linkX - 50, linkY + 2.7, { url: linkURL });
    }
  }
}