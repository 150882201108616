import { Component, Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { UploadsComponent } from "../uploads/uploads.component";
import Swal from "sweetalert2";
import { EditUploadsService } from "../edit-uploads.service";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        year: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        day: parseInt(date[0], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : '';
  }
}

@Component({
  selector: 'app-edit-uploads',
  templateUrl: './edit-uploads.component.html',
  styleUrls: ['./edit-uploads.component.scss'],
  providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class EditUploadsComponent {
  //@Input() currentRecord: any;
  investmentForm: any = FormGroup;
  getquestion: any = FormGroup;
  isSubmitted = false;
  type: any;
  transaction: any;
  ccy: any;
  type2: any;
  oldtransaction: any;
  oldinvestment: any;
  bank_date: Date | undefined;
  readonly DELIMITER = '/';

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10),
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
	}

  constructor(private formBuilder: FormBuilder, private http:HttpService,private spinner: NgxSpinnerService,private sharedService: UploadsComponent,public editUploadsService: EditUploadsService) {}

  ngOnInit(): void {
    this.investmentForm = this.editUploadsService.investmentForm
    this.bank_date = this.editUploadsService.bank_date
    this.getquestion = this.editUploadsService.getquestion
    this.type = this.editUploadsService.type
    this.transaction = this.editUploadsService.transaction
    this.ccy = this.editUploadsService.ccy
    this.type2 = this.editUploadsService.type2
    this.oldtransaction = this.editUploadsService.oldtransaction
    this.oldinvestment = this.editUploadsService.oldinvestment
  }

  get errorControl() {
    return this.investmentForm.controls;
  }
  onSubmit(){

    const fieldsToFormat = ['Bank_Date', 'Date', 'Months'];

    fieldsToFormat.forEach(field => {
      if (this.investmentForm.value[field]) {
        if (field === 'Months') {
          this.investmentForm.value[field] = moment(this.investmentForm.value[field]).format('MM');
        } else {
          this.investmentForm.value[field] = moment(this.investmentForm.value[field]).format('YYYY-MM-DD');
        }
      }
    });

    this.isSubmitted = true;

    if (this.investmentForm.invalid) {
      return;
    }

    this.closeModal()
    // console.log(this.investmentForm.value['Id'])
    // return false
    const rawData = this.investmentForm.value;
    this.http.updateData('investments',this.investmentForm.value['Id'], rawData).subscribe((response: any) => {

        Swal.fire({
          icon: "success",
          text: "Investment successfully updated.",
          showConfirmButton: false,
          timer: 3000
        });
        this.investmentForm.reset();

      },
      (error) => {
        console.error('Error:', error);
        // Handle errors
      }
    );
  }
  onOpenCalendar(container: any) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  ShowError(control: any, isSubmitted: any, content: any) {
      let html = '';
      if(isSubmitted && control.errors) {
        const icon = '<img alt="" src="assets/images/icon/error.png" class="error-icon">';
        if(control.errors?.required) {
          html += '<label class="error">'+icon+' '+content+' is Required</label>';
        }
        if(control.errors?.pattern) {
          html += '<label class="error">'+icon+' Numeric value only</label>';
        }
        if(control.errors?.invalidPercentage) {
          html += '<label class="error">'+icon+' percentage value only</label>';
        }
      }
      return html;
  }
  percentageValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (Validators.required(control) || Validators.pattern(/^(\d+(\.\d{1,2})?|\.\d{1,2})%$/)(control)) {
        return { 'invalidPercentage': { value } };
      }
      return null;
    };
  }

  closeModal() {
    this.isSubmitted = false;
    this.sharedService.isClassEdited = false;
    // this.sharedService.getInvestments(1)
  }

}
