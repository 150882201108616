
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="">
    <div class="child">
      <div class="row">

        <div class="col-md-12 select-container">        
          <form style="text-align: -webkit-center;">
            <div class="form-group">
              <label class="form-label"><b>Upload Type:</b></label>
              <select
                class="custom-select"
                [(ngModel)]="uploadTypeId"
                (change)="onSelect($event)"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="0">------- Select -------</option>
                <option
                  *ngFor="let uploadtype of uploadTypes"
                  value="{{ uploadtype.id }}"
                >
                  {{ uploadtype.name }}
                </option>
              </select>
            </div>
            <div *ngIf="showSubtype" class="form-group">
              <label class="form-label"><b>{{subTypeTitle}}:</b></label>
              <select
                class="custom-select"
                [(ngModel)]="subTypeId"
                (change)="onSelectSubType($event)"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="0">------- Select -------</option>
                <option
                  *ngFor="let subtype of subTypes"
                  value="{{ subtype.id }}"
                >
                  {{ subtype.name }}
                </option>
              </select>
            </div>

            <div *ngIf="showInvestor" class="form-group">
              <label class="form-label"><b>Investor Type:</b></label>
              <select
                class="custom-select"
                [(ngModel)]="investorType"
                (change)="onSelectInvestor($event)"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="">------- Select -------</option>
                <option
                  *ngFor="let inv of investorTypes"
                  value="{{ inv.id }}"
                >
                  {{ inv.name }}
                </option>
              </select>
            </div>
            <div *ngIf="showTransaction" class="form-group">
              <label class="form-label"><b>Transaction:</b></label>
              <select
                class="custom-select"
                [(ngModel)]="transaction"
                (change)="onSelectTransaction($event)"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="">------- Select -------</option>
                <option
                  *ngFor="let trs of transactions"
                  value="{{ trs.id }}"
                >
                  {{ trs.name }}
                </option>
              </select>
            </div>


            <div *ngIf="showEtrDataTypes" class="form-group">
              <label class="form-label"><b>Data Type:</b></label>
              <select
                class="custom-select"
                [(ngModel)]="etr"
                (change)="onSelectEtrDataType($event)"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="">------- Select -------</option>
                <option
                  *ngFor="let st of dataTypes"
                  value="{{ st.id }}"
                >
                  {{ st.name }}
                </option>
              </select>
            </div>

            <div *ngIf="showButtons" class="form-group">
              <a target="_blank" class="download-link-a" (click)="downloadTemplate()">
                Download Template
               </a>
               <br/>   
               <div >              
                <div class="d-flex justify-content-center p-1 position-relative">
                  <button type="button" (click)="fileInput1.click()"
                  class="btn-cls d-block" >Validate File
                  <img class="img1" src="assets/images/icon/upload.png" /></button>
                   <input (change)="fileChangeValidate($event)" #fileInput1 type="file" name="file" id="fileValidate" hidden #fileUploader />
                   <!-- <div class="loader_gif" *ngIf="showValidateLoader"><img width="20px" src="assets/images/icon/loader-1.gif" /></div> -->
                   <div class="loader_gif " *ngIf="showValidateLoader" ><img width="20px" src="assets/images/icon/loader-1.gif" /></div>
               </div>
               <div class="d-flex justify-content-center position-relative">
                <button type="button" (click)="fileInput.click()"
                class="btn-cls d-block" >Upload Data
                <img class="img1" src="assets/images/icon/upload.png" /></button>
                  <input (change)="fileChange($event)" #fileInput type="file" name="file" id="file" hidden #fileUploader />
                  <!-- <div class="loader_gif loader_gif me-3 mt-1" *ngIf="showUploadLoader"><img width="20px" src="assets/images/icon/loader-1.gif" /></div> -->
                  <div class="loader_gif loader_gif " *ngIf="showUploadLoader" ><img width="20px" src="assets/images/icon/loader-1.gif" /></div>
               </div>    
              </div> 
            </div>
          </form>
        </div>
      </div>
    </div>

</div>
<!-- come-from-modal right -->
<div
  *ngIf="showInvestor && showTransaction && isClassAdded"
  class="modal fade show"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header justify-content-between">
        <h5 class="modal-title">
          Add Investment {{transactionType}}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body padding-model form-editing">
        <app-add-edit-investments
          [isUpload]="true"
          [selectedTransactionType]="transactionType"
          (closeDialogEvent)="closeModal()"
        />
      </div>
    </div>
  </div>
</div>

<div *ngIf="showEtr && isEtrClassAdded" [ngClass]="{'show': isEtrClassAdded}" class="modal fade " tabindex="-1" role="dialog" >
<div class="add-etr-modal modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header justify-content-between">
      <h5 class="modal-title">Add {{etrName}}</h5>
      <button type="button" class="close" data-dismiss="modal"(click)="closeEtrModal()" >
        <span>&times;</span>
      </button>
    </div>
    <div class="modal-body padding-model form-editing">
      <app-add-etr  (closeDialogEvent)="showEtr = $event">
      </app-add-etr>
    </div>
  </div>
</div>
</div>