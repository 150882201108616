import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FxrateInterfce } from '../../interfaces/fxrate-response.interface';
import { ParseDate } from '../../../../shared/helpers/utils';
import { PercentValueFormatter4 } from '../../../../shared/helpers/percent-value-formatter';
import { PercentFormatter } from '../../../../shared/helpers/percent-formatter.helper';
import { SimpleNumberValueFormatter } from '../../../../shared/helpers/number-formatter.helper';

@Injectable({ providedIn: 'root' })
export class FxRateFormProvider {
  fxRateForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  initFxRateForm(data?: FxrateInterfce): FormGroup {
    return (this.fxRateForm = this.formBuilder.group({
      date: [data?.date ? ParseDate(data.date) : null, [Validators.required]],
      buyRate: [data?.buyRate ? PercentFormatter(data.buyRate * 100, false) : null, [Validators.required, Validators.min(-100), Validators.max(100)]],
      us10y: [data?.us10y ? PercentFormatter(data.us10y * 100, false) : null, [Validators.min(-100), Validators.max(100)]],
      us3y: [data?.us3y ? PercentFormatter(data.us3y * 100, false) : null, [Validators.min(-100), Validators.max(100)]],
      gbp: [data?.gbp ? SimpleNumberValueFormatter(data?.gbp, 4) : null, [greaterThanZeroValidator(), Validators.max(99.9999)]],
      usd: [data?.usd ? SimpleNumberValueFormatter(data?.usd, 4) : null, [greaterThanZeroValidator(), Validators.max(99.9999)]],
      eonia: [data?.eonia ? this.changePercent(data.eonia) : null, [Validators.min(-900), Validators.max(900)]],
      libor: [data?.libor ? this.changePercent(data.libor) : null, [Validators.min(-900), Validators.max(900)]],
      obfr: [data?.obfr ? this.changePercent(data.obfr) : null, [Validators.min(-900), Validators.max(900)]],
      demand: [data?.demand ? this.changePercent(data.demand) : null, [Validators.min(-100), Validators.max(100)]],
      fixed: [data?.fixed ? this.changePercent(data.fixed) : null, [Validators.min(-100), Validators.max(100)]],
      term: [data?.term ? this.changePercent(data.term) : null, [Validators.min(-100), Validators.max(100)]],
    }));
  }

  private countDecimalPlaces(number: number) {
    // Convert the number to a string
    const numberStr = number.toString();

    // Split the string on the decimal point
    if (numberStr.includes('.')) {
      const decimalPart = numberStr.split('.')[1];
      console.log(decimalPart.length >= 5 ? 2 : decimalPart.length);
      return decimalPart.length >= 4 ? 4 : decimalPart.length;
    } else {
      // No decimal point means 0 decimal places
      return 0;
    }
  }

  private changePercent(value: number) {
    return PercentValueFormatter4({ value }).replace('%', '');
  }
}

export function greaterThanZeroValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value && value <= 0) {
      return { greaterThanZero: true };
    }
    return null;
  };
}
