import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { ColDef, GridReadyEvent, SideBarDef } from '@ag-grid-community/core';
import { COL_DEFS } from './table-columns.const';
import { generateDateRange, generateYearRange } from '../../shared/helpers/generate-date-range';
import { FxrateInterfce, FxrateResponseInterface } from './interfaces/fxrate-response.interface';
import { FxrateQueryParamsInterface } from './interfaces/fxrate-query-params.interface';
import { SummaryKeyFxrate } from '../../shared/enums/common.enum';
import { queryCleanerModel } from '../../shared/helpers/query-cleaner-model';
import { FXRATE_PARAMS_KEYS } from './fxrate.helper';
import { FxrateHttpService } from './services/fxrate-http.service';
import { CalculatePinnedFxrate } from '../../shared/helpers/calculate-pinned-fxrate';
import { Summary } from 'src/app/shared/interfaces/base-response-interface';
import { setNormal, setPrinterFriendly } from '../../shared/helpers/print';

@Component({
  selector: 'app-fxrate',
  templateUrl: './fxrate.component.html',
  styleUrls: ['./fxrate.component.scss'],
})
export class FxrateComponent {
  isLoading: boolean = true;
  savedFilterModel: any = localStorage.getItem('savedFilterCfxModel');
  sortingState: any = localStorage.getItem('sortingfxState');
  colDefs: ColDef[] = COL_DEFS(this);
  paginationPageSize = 50;
  gridApi: any;
  dateRange: any = null;
  currentFilter: any = 'All';
  financialList: { id: string; name: string }[] = generateYearRange();
  selectedFinancialYear: any = '';
  selectedMonth: any = '';
  flag: boolean = false;
  selectedRecordPerPage: any = '50';

  //// new code
  monthList: any = generateDateRange();
  queryParams: Partial<FxrateQueryParamsInterface> = {
    pageIndex: 0,
    perPage: 50,
    sort: ['-date'],
  };
  defaultQueryParams: Partial<FxrateQueryParamsInterface> = {
    ...this.queryParams,
  };
  rowData!: any[];
  params: any;
  currentPageIndex: any = 0;
  recordsPerPage: any = 50;
  totalRecords: any = 0;
  totalRecordsDisplay: any = 0;
  totalPage: any = 0;
  totalPageString: any;
  defaultColDef: ColDef = {
    // minWidth: 100,
    floatingFilter: true,
    // flex: 1,
    rowDrag: false,
    suppressMovable: true,
  };
  pinnedRowData!: Summary;
  isShowAddEditFxRates: boolean;
  currentRecord: any;

  private target: any;
  public sideBar: SideBarDef | null;

  constructor(private fxRateHttpService: FxrateHttpService) {}

  resetFilters() {
    this.queryParams = { ...this.defaultQueryParams };
    const listDate = FXRATE_PARAMS_KEYS;
    listDate.forEach((listDate) => {
      const filterComponent = this.gridApi.getFilterInstance(listDate);
      if (filterComponent) {
        filterComponent.setModel(null);
      }
    });
    this.gridApi.resetColumnState();
    this.gridApi.refreshHeader();
    this.gridApi.setQuickFilter('');
    this.selectedMonth = '';
    this.selectedFinancialYear = '';
    this.gridApi.refreshCells();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.params = params;
    this.onGridRefresh();
  }

  saveFilterData(event: any, type?: string) {
    const sortingState = this.gridApi.getColumnState();
    localStorage.setItem('sortingState', JSON.stringify(sortingState));
    this.savedFilterModel = this.gridApi.getFilterModel();
    localStorage.setItem('savedFilterModel', JSON.stringify(this.savedFilterModel));
    let sortColumn = sortingState.filter((item: any) => item.sort != null)[0];
    if (sortColumn) {
      event.api.showLoadingOverlay();
      if (sortColumn.sort == 'asc') this.queryParams['sort'] = sortColumn.colId;
      else this.queryParams['sort'] = '-' + sortColumn.colId;
      this.getDataOnFilterChange();
    }

    localStorage.setItem('sortingState', JSON.stringify(sortingState));
    this.savedFilterModel = this.gridApi.getFilterModel();
    localStorage.setItem('savedFilterModel', JSON.stringify(this.savedFilterModel));
    if (event.api != undefined) {
      event.api.refreshCells();
    }

    Swal.fire({
      icon: 'success',
      text: 'Layout successfully saved.',
      showConfirmButton: false,
      timer: 3000,
    });
  }

  private getDataOnFilterChange(): void {
    this.getFxRateData(this.queryParams);
  }

  refreshPage(): void {
    this.resetFilters();
  }

  onGridRefresh(): void {
    this.isLoading = true;
    this.currentRecord = void 0;
    this.rowData = [];
    this.getFxRateData(this.queryParams);
  }

  private getFxRateData(queryparams: any): void {
    this.getBottomData();
    this.fxRateHttpService.getFxRateData(queryparams).subscribe({
      next: (response: FxrateResponseInterface) => {
        this.rowData = response.items;
        this.totalRecords = response.meta.total;
        this.totalRecordsDisplay = this.totalRecords.toLocaleString('en-US');
        this.recordsPerPage = response.meta.perPage;
        this.currentPageIndex = response.meta.pageIndex;
        this.totalPage = Math.ceil(this.totalRecords / this.recordsPerPage);
        this.totalPageString = this.totalPage.toLocaleString('en-US');
        let snostartno = 1;
        if (this.currentPageIndex != 0) {
          snostartno = (this.currentPageIndex + 1) * (this.queryParams?.perPage || 0) - ((this.queryParams.perPage || 0) - 1);
        }
        for (let i = 0; i < response.items.length; i++) {
          response.items[i].no = i + snostartno;
        }
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getBottomData() {
    this.fxRateHttpService.getFxRateSummaryData(this.queryParams).subscribe((value) => {
      this.pinnedRowData = value;
      this.setPinnedData();
    });
  }

  setPinnedData(key: keyof Summary = 'avg', type?: SummaryKeyFxrate): void {
    setTimeout(() => {
      let pinnedBottomData = this.generatePinnedBottomData(key, type);
      this.gridApi.setGridOption('pinnedBottomRowData', [pinnedBottomData]);
    }, 500);
  }

  filterChanged(filter: Partial<FxrateQueryParamsInterface>): void {
    this.queryParams = { ...this.queryParams, ...filter };
    this.paginationPageSize = filter.perPage!;
    this.onGridRefresh();
  }

  changePageSelection(pageIndex: number) {
    if (pageIndex >= 0 && pageIndex < this.totalPage) {
      this.currentPageIndex = pageIndex;
      this.queryParams['pageIndex'] = this.currentPageIndex;
      this.onGridRefresh();
    }
  }

  onSortChanged(event: any) {
    const sortingState = this.gridApi.getColumnState();
    this.savedFilterModel = this.gridApi.getFilterModel();
    let sortColumn = sortingState.filter((item: any) => item.sort != null)[0];
    if (sortColumn) {
      event?.api?.showLoadingOverlay();
      if (sortColumn.sort == 'asc') this.queryParams['sort'] = sortColumn.colId === 'week' ? 'date' : sortColumn.colId;
      else this.queryParams['sort'] = '-' + (sortColumn.colId === 'week' ? 'date' : sortColumn.colId);

      this.getDataOnFilterChange();
    } else {
      this.queryParams.sort = this.defaultQueryParams.sort;
      this.getDataOnFilterChange();
    }
  }

  onFilterChanged(event: any) {
    event.api.showLoadingOverlay();
    if (event.api != undefined) {
      let filterModel = this.gridApi.getFilterModel();
      filterModel = {
        ...filterModel,
        ...(filterModel.buyRate && { buyRate: { ...filterModel.buyRate, filter: (Number.isInteger(+filterModel.buyRate.filter) ? filterModel.buyRate.filter : filterModel.buyRate.filter / 100).toFixed(Number.isInteger(+filterModel.buyRate.filter) ? 0 : 3) } }),
        ...(filterModel.eonia && { eonia: { ...filterModel.eonia, filter: (Number.isInteger(+filterModel.eonia.filter) ? filterModel.eonia.filter : filterModel.eonia.filter / 100).toFixed(Number.isInteger(+filterModel.eonia.filter) ? 0 : 3) } }),
        ...(filterModel.libor && { libor: { ...filterModel.libor, filter: (Number.isInteger(+filterModel.libor.filter) ? filterModel.libor.filter : filterModel.libor.filter / 100).toFixed(Number.isInteger(+filterModel.libor.filter) ? 0 : 3) } }),
        ...(filterModel.obfr && { obfr: { ...filterModel.obfr, filter: (Number.isInteger(+filterModel.obfr.filter) ? filterModel.obfr.filter : filterModel.obfr.filter / 100).toFixed(Number.isInteger(+filterModel.obfr.filter) ? 0 : 3) } }),
        ...(filterModel.demand && { demand: { ...filterModel.demand, filter: (Number.isInteger(+filterModel.demand.filter) ? filterModel.demand.filter : filterModel.demand.filter / 100).toFixed(Number.isInteger(+filterModel.demand.filter) ? 0 : 3) } }),
        ...(filterModel.fixed && { fixed: { ...filterModel.fixed, filter: (Number.isInteger(+filterModel.fixed.filter) ? filterModel.fixed.filter : filterModel.fixed.filter / 100).toFixed(Number.isInteger(+filterModel.fixed.filter) ? 0 : 3) } }),
        ...(filterModel.term && { term: { ...filterModel.term, filter: (Number.isInteger(+filterModel.term.filter) ? filterModel.term.filter : filterModel.term.filter / 100).toFixed(Number.isInteger(+filterModel.term.filter) ? 0 : 3) } }),
      };
      const currentColumnId = event.columns[0].colId;
      this.queryParams = queryCleanerModel(this.queryParams, filterModel, currentColumnId, FXRATE_PARAMS_KEYS);
      this.getDataOnFilterChange();
    }
  }

  generatePinnedBottomData(key: keyof Summary, type?: SummaryKeyFxrate) {
    // generate a row-data with null values
    let result: any = {};
    this.gridApi.getAllGridColumns().forEach((item: any) => {
      result[item.colId] = null;
    });

    this.target = CalculatePinnedFxrate(this.pinnedRowData, this.target, result, key, type);
    return this.target;
  }

  changePinnedRowType = (event: { value: keyof Summary; column: SummaryKeyFxrate }) => {
    const self = this;
    self.setPinnedData(event.value, event.column);
  };

  openModal(data?: FxrateInterfce) {
    this.currentRecord = data;
    this.isShowAddEditFxRates = true;
  }

  closeModal() {
    this.isShowAddEditFxRates = false;
  }

  exportData() {
    this.gridApi.exportDataAsExcel();
  }

  onPrint() {
    setPrinterFriendly(this.gridApi);
    setTimeout(() => {
      print();
      setNormal(this.gridApi);
    }, 2000);
  }
}
