import { isScientificNotation } from './is-scientific';

export const numberValueFormatter = (params: any) => {
  const originalValue = params.value;
  let formattedValue = '-';
  // Check if the row is pinned
  if (params?.node?.rowPinned) {
    formattedValue =
      originalValue?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || '';
  } else {
    if (originalValue != 0 && originalValue != null && !isScientificNotation(originalValue)) {
      formattedValue = originalValue?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      formattedValue = '-';
    }
  }
  return formattedValue;
};

export const numberValueFormatter2 = (params: any) => {
  const originalValue = params.value;
  let formattedValue = '-';
  const decimal = decimalPointSettings[params.colDef.colId] || decimalPointSettings[params.colDef.colId] === 0 ? decimalPointSettings[params.colDef.colId] : 2;

  // Check if the row is pinned
  if (params?.node?.rowPinned) {
    formattedValue =
      originalValue?.toLocaleString('en-US', {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      }) || '';
  } else {
    if (originalValue != 0 && originalValue != null) {
      formattedValue = originalValue?.toLocaleString('en-US', {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    }
  }
  return formattedValue;
};

const decimalPointSettings: Record<string, number> = {
  gbp: 4,
  usd: 4,
  buyRate: 3,
  months: 0,
  fx_rate: 4,
  importedRecords: 0,
  orphans: 0,
  totalBanks: 0,
  iban: 0,
  accountNumber: 0,
  tradedFx:4,
  settledFx:4,
  closedFx:4,
};
