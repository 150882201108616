import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { FullComponent } from './layouts/full/full.component';
import { InvestmentsComponent } from './investments/investments/investments.component';
import { UploadsComponent } from './uploads/uploads/uploads.component';
import { InvestmentComponent } from './crystalreports/investment/investment.component';
import { LoginComponent } from './login/login.component';
import { MasterbankComponent } from './crystalreports/masterbank/masterbank.component';

import { FxrateComponent } from './crystalreports/fxrate/fxrate.component';
import { ChartsComponent } from './charts/charts.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'cte/investments',
        component: InvestmentsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'charts',
        component: ChartsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'reports/investment',
        component: InvestmentComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'reports/masterbank',
        component: MasterbankComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'cte/transactions',
        loadChildren: () => import('./cash-transaction/cash-transactions.module').then((m) => m.CashTransactionsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'finance/transactions',
        loadChildren: () => import('./finance-transactions/finance-transactions.module').then((m) => m.FinanceTransactionsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'finance/ledger',
        loadChildren: () => import('./finance-ledger/finance-ledger.module').then((m) => m.FinanceLedgerModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'reports/checkbank',
        loadChildren: () => import('././crystalreports/check-bank/check-bank.module').then((m) => m.CheckBankModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'cte/fxrate',
        component: FxrateComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'entities',
        loadChildren: () => import('./entities/entities/entities.module').then((m) => m.EntitiesModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/people',
        loadChildren: () => import('./entities/people/people.module').then((m) => m.PeopleModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/investors',
        loadChildren: () => import('./entities/investors/investors.module').then((m) => m.InvestorsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/intermediaries',
        loadChildren: () => import('./entities/intermediaries/intermediaries.module').then((m) => m.IntermediariesModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/originators',
        loadChildren: () => import('./entities/originators/originators.module').then((m) => m.OriginatorsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/debtors',
        loadChildren: () => import('./entities/debtors/debtors.module').then((m) => m.DebtorsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/creditors',
        loadChildren: () => import('./entities/creditors/creditors.module').then((m) => m.CreditorsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/leasees',
        loadChildren: () => import('./entities/leasees/leasees.module').then((m) => m.LeaseesModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/suppliers',
        loadChildren: () => import('./entities/suppliers/suppliers.module').then((m) => m.SuppliersModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'entities/credebtors',
        loadChildren: () => import('./entities/credebtor/credebtor.module').then((m) => m.CredebtorModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'tte/asset',
        loadChildren: () => import('./entities/asset/asset.module').then((m) => m.AssetModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'tte/contracts',
        loadChildren: () => import('./contracts/contracts.module').then((m) => m.ContractsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'reports/bankaudit',
        loadChildren: () => import('./crystalreports/bank-audit/bank-audit.module').then((m) => m.BankAuditModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'uploadreports/upload',
        component: UploadsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'about',
        loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then((m) => m.ComponentsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'tte/transactions',
        loadChildren: () => import('./trade/trade-transactions-module').then((m) => m.TradeTransactionsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'tte/algorithms',
        loadChildren: () => import('./algorithms/algorithms.module').then((m) => m.AlgorithmsModule),
        canActivate: [MsalGuard],
        data: { group: 'tte' },
      },
      {
        path: 'cte/identification',
        loadChildren: () => import('./algorithms/algorithms.module').then((m) => m.AlgorithmsModule),
        canActivate: [MsalGuard],
        data: { group: 'cte' },
      },
      {
        path: 'tte/investors',
        loadChildren: () => import('./investors/investors.module').then((m) => m.InvestorsModule),
        canActivate: [MsalGuard],
      },
      {
        path: 'finance/models',
        loadChildren: () => import('./accounting/models/models.module').then((m) => m.ModelsModule),
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(Approutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
