import { Component, HostListener} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-crystalreport',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent {  
    // Variable to control the visibility of the loader
    isLoading: boolean = true;
    DefaultUrl: string='https://reports.credebt.exchange?height=';
    // DefaultUrl: string='https://reports.credebt.exchange?height=';
    iframeSource: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.DefaultUrl);
    constructor(private sanitizer: DomSanitizer) { }   
  onIframeLoad(event: Event): void {     
    this.isLoading = false;     
    const iframe = event.target as HTMLIFrameElement;    
     if (iframe.contentWindow) {  
      iframe.height= this.innerheight+"px";    
      iframe.width= iframe.offsetWidth+"px"; 
      }
  }
  public innerheight: any;
  public innerWidth: any; 
 
ngOnInit() {
  this.SetIframe();  
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {    
    this.SetIframe();     
  } 
  GetHeaderHeight() {
    return document.getElementById("header-row")?.clientHeight;
  }
  SetIframe()
  {
    this.isLoading = true;  
    const Headerheight:any= this.GetHeaderHeight();   
    const Iframeheight:any= (window.innerHeight-Headerheight);
    this.innerheight=Iframeheight;
    this.innerWidth = window.innerWidth;    
    this.iframeSource= this.sanitizer.bypassSecurityTrustResourceUrl(this.DefaultUrl+((Iframeheight-50)+"px"));
  }
}



