export const FlashMessageType = {
  Error: {
    text: 'alert-danger',
    label: 'Error',
    icon: 'bi-x-circle-fill',
  },
  Warning: {
    text: 'alert-warning',
    label: 'Warning',
    icon: 'bi-exclamation-triangle-fill',
  },
  Success: {
    text: 'alert-success',
    label: 'Success',
    icon: 'bi-check-circle-fill',
  },
  Info: {
    text: 'alert-info',
    label: 'Info',
    icon: 'bi-info-circle-fill',
  },
};
