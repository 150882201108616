export const demoDataQuater = [
  { quarter: 'Q1\n2016', aETR: 45000000.55, bETR: 560000.5655, cETR: 6005656, dETR: 600033.26, fETR: 7000003656 },
  { quarter: 'Q2\n2016', aETR: 950000.55, bETR: 890000.5655, cETR: 605656, dETR: 600033.26, fETR: 70087036.56 },
  { quarter: 'Q3\n2016', aETR: 45072000.55, bETR: 5687400.5655, cETR: 8745656, dETR: 876033.26, fETR: 98000036.56 },
  { quarter: 'Q4\n2016', aETR: 25000000.55, bETR: 354700.5655, cETR: 2105656, dETR: 956033.26, fETR: 53000036.56 },
  { quarter: 'Q1\n2017', aETR: 270000555, bETR: 5600005555, cETR: 600005555, dETR: 6000045555, fETR: 7000005.55 },
  { quarter: 'Q1\n2018', aETR: 180000555, bETR: 560000555, cETR: 6000055, dETR: 60000555, fETR: 7000005.55 },
];
export const demoDataWeek = [
  { quarter: 'W1', aETR: 45000000.55, bETR: 560000.5655, cETR: 6005656, dETR: 600033.26, fETR: 7000003656 },
  { quarter: 'W2', aETR: 950000.55, bETR: 890000.5655, cETR: 605656, dETR: 600033.26, fETR: 70087036.56 },
  { quarter: 'W3', aETR: 45072000.55, bETR: 5687400.5655, cETR: 8745656, dETR: 876033.26, fETR: 98000036.56 },
  { quarter: 'W4', aETR: 25000000.55, bETR: 354700.5655, cETR: 2105656, dETR: 956033.26, fETR: 53000036.56 },
  { quarter: 'W5', aETR: 270000555, bETR: 5600005555, cETR: 600005555, dETR: 6000045555, fETR: 7000005.55 },
  { quarter: 'W6', aETR: 180000555, bETR: 560000555, cETR: 6000055, dETR: 60000555, fETR: 7000005.55 },
];
export const demoDataMonth = [
    { quarter: 'Jan-2016', aETR: 45000000.55, bETR: 560000.5655, cETR: 6005656, dETR: 600033.26, fETR: 7000003656 },
    { quarter: 'Feb-2016', aETR: 950000.55, bETR: 890000.5655, cETR: 605656, dETR: 600033.26, fETR: 70087036.56 },
    { quarter: 'Jan-2017', aETR: 45072000.55, bETR: 5687400.5655, cETR: 8745656, dETR: 876033.26, fETR: 98000036.56 },
    { quarter: 'Mar-2017', aETR: 25000000.55, bETR: 354700.5655, cETR: 2105656, dETR: 956033.26, fETR: 53000036.56 },
    { quarter: 'April-2018', aETR: 270000555, bETR: 5600005555, cETR: 600005555, dETR: 6000045555, fETR: 7000005.55 },
    { quarter: 'Jan-2019', aETR: 180000555, bETR: 560000555, cETR: 6000055, dETR: 60000555, fETR: 7000005.55 },
]
export const demoDataYear = [
    { quarter: '2016', aETR: 45000000.55, bETR: 560000.5655, cETR: 6005656, dETR: 600033.26, fETR: 7000003656 },
    { quarter: '2017', aETR: 950000.55, bETR: 890000.5655, cETR: 605656, dETR: 600033.26, fETR: 70087036.56 },
    { quarter: '2018', aETR: 45072000.55, bETR: 5687400.5655, cETR: 8745656, dETR: 876033.26, fETR: 98000036.56 },
    { quarter: '2019', aETR: 25000000.55, bETR: 354700.5655, cETR: 2105656, dETR: 956033.26, fETR: 53000036.56 },
    { quarter: '2018', aETR: 270000555, bETR: 5600005555, cETR: 600005555, dETR: 6000045555, fETR: 7000005.55 },
    { quarter: '2020', aETR: 180000555, bETR: 560000555, cETR: 6000055, dETR: 60000555, fETR: 7000005.55 },
]