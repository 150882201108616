import { SummaryKeyFxrate } from '../enums/common.enum';
import { Summary, SummaryItem } from '../interfaces/base-response-interface';

export function CalculatePinnedFxrate(thisPinnedRowData: Summary, thisTarget: any, target: any, key: keyof Summary, type?: SummaryKeyFxrate) {
  const columnsWithAggregation = ['buyRate', 'gbp', 'usd', 'eonia', 'libor', 'obfr', 'date', 'demand', 'fixed', 'term'];

  if (!thisTarget) {
    thisTarget = { ...target };
  }

  if (!thisPinnedRowData) {
    return {
      ...thisTarget,
      buyRate: '-',
      gbp: '-',
      usd: '-',
      eonia: '-',
      libor: '-',
      obfr: '-',
      demand: '-',
      fixed: '-',
      term: '-',

      date: 'sum',
    };
  }

  columnsWithAggregation.forEach((element) => {
    if (element === 'date') {
      thisTarget[element] = key;
      return;
    }

    // @todo when need every column deferent select write code like this
    /*if (element === 'date' && (type ? type === SummaryKeyFxrate.Gbp : true)) {
      thisTarget[element] = key;
      return;
    }*/

    if (element == SummaryKeyFxrate.BuyRate && (type ? type === SummaryKeyFxrate.BuyRate : true)) {
      thisTarget[element] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === element)?.value || '-';
      thisTarget[SummaryKeyFxrate.Gbp] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === SummaryKeyFxrate.Gbp)?.value || '-';
      thisTarget[SummaryKeyFxrate.Usd] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === SummaryKeyFxrate.Usd)?.value || '-';
      thisTarget[SummaryKeyFxrate.Eonia] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === SummaryKeyFxrate.Eonia)?.value || '-';
      thisTarget[SummaryKeyFxrate.Libor] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === SummaryKeyFxrate.Libor)?.value || '-';
      thisTarget[SummaryKeyFxrate.Obfr] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === SummaryKeyFxrate.Obfr)?.value || '-';
      thisTarget[SummaryKeyFxrate.Demand] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === SummaryKeyFxrate.Demand)?.value || '-';
      thisTarget[SummaryKeyFxrate.Fixed] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === SummaryKeyFxrate.Fixed)?.value || '-';
      thisTarget[SummaryKeyFxrate.Term] = thisPinnedRowData[key]?.find((val: SummaryItem) => val?.key === SummaryKeyFxrate.Term)?.value || '-';
      return;
    }
  });
  return thisTarget;
}
