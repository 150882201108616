<div *ngIf="isLoading" class="loader_main"></div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>

<div class="iframe-container">
    <div class="card mb-0 common-header" id="header-row">
        <app-grid-filter
                [label]="'Fx & Overnight Rates'"
                [currentFilter]="currentFilter"
                [selectedMonth]="selectedMonth"
                [selectedRecordPerPage]="selectedRecordPerPage"
                [selectedFinancialYear]="selectedFinancialYear"
                [dateRange]="dateRange"
                [showNewButton]="true"
                [savedFilterModel]="savedFilterModel"
                (saveFilterData)="saveFilterData($event)"
                (refreshPage)="refreshPage()"
                (formChange)="filterChanged($event)"
                (createNew)="openModal()"
                (export)="exportData()"
                (print)="onPrint()"
        />
    </div>
    <app-grid-container
            [gridRowData]="rowData"
            [gridColDefs]="colDefs"
            [gridDefaultColDef]="defaultColDef"
            [gridPaginationPageSize]="paginationPageSize"
            [hasPagination]="false"
            (gridReady)="onGridReady($event)"
            (filterChanged)="onFilterChanged($event)"
            (sortChanged)="onSortChanged($event)"
    />
</div>
<app-paginator
  [currentPageIndex]="currentPageIndex"
  [paginationPageSize]="paginationPageSize"
  [totalPage]="totalPage"
  [totalPageString]="totalPageString"
  [totalRecordsDisplay]="totalRecordsDisplay"
  (pageChange)="changePageSelection($event)"
/>

<div *ngIf="isShowAddEditFxRates"
     class="modal fade show"
     tabindex="-1"
     role="dialog"
>
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h5 class="modal-title">
                    {{ currentRecord ? "Edit" : "Add" }} Fx & Overnight Rates
                </h5>
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        (click)="closeModal()"
                >
                    <span>&times;</span>
                </button>
            </div>
            <div class="modal-body padding-model form-editing">
                <app-add-edit-fxrate
                        [fxRateData]="currentRecord"
                        (closeDialogEvent)="isShowAddEditFxRates = $event"
                        (createUpdateEvent)="onGridRefresh()"
                />
            </div>
        </div>
    </div>
</div>
