import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MsalModule } from '@azure/msal-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgGridAngular } from '@ag-grid-community/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { GridFilterComponent } from './components/grid-filter/grid-filter.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridContainerComponent } from './components/grid-container/grid-container.component';
import { FormErrorComponentComponent } from './components/form-error-component/form-error-component.component';
import { CustomSelectSearchFilterComponent } from './components/custom-select-search-filter/custom-select-search-filter.component';
import { NgToggleModule } from 'ng-toggle-button';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { PercentNumberDirective } from './directives/percent-number.directive';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { NumberValueFormatterPipe } from './pipes/number-value-formatter.pipe';
import { AdvancedNumberValueFormaterPipe } from './pipes/advanced-number-value-formater.pipe';
import { PercentValueFormatterPipe } from './pipes/percent-value-formatter.pipe';
import { FlashMessageComponent } from './components/flash-message/flash-message.component';
import { HtmlToPdfComponent } from './html-to-pdf/html-to-pdf.component';
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  declarations: [
    PaginatorComponent,
    GridContainerComponent,
    GridFilterComponent,
    FormErrorComponentComponent,
    CustomSelectSearchFilterComponent,
    OnlyNumberDirective,
    PercentNumberDirective,
    NumberValueFormatterPipe,
    AdvancedNumberValueFormaterPipe,
    PercentValueFormatterPipe,
    FlashMessageComponent,
    HtmlToPdfComponent,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    NgbModule,
    MsalModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    AgGridAngular,
    NgSelectModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgToggleModule,
  ],
  exports: [
    NgbModule,
    MsalModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    AgGridAngular,
    NgSelectModule,
    PaginatorComponent,
    GridContainerComponent,
    GridFilterComponent,
    FormErrorComponentComponent,
    NgToggleModule,
    OnlyNumberDirective,
    PercentNumberDirective,
    MatExpansionModule,
    MatRadioModule,
    MatButtonModule,
    MatTableModule,
    MatCheckboxModule,
    MatCardModule,
    MatTabsModule,
    NumberValueFormatterPipe,
    AdvancedNumberValueFormaterPipe,
    PercentValueFormatterPipe,
    FlashMessageComponent,
    HtmlToPdfComponent,
    TruncatePipe,
  ],
})
export class SharedModule {}
