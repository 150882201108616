import { Component, Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadsComponent } from '../uploads/uploads.component';
import Swal from 'sweetalert2';
import { EditUploadsService } from '../edit-uploads.service';
import {
  intermediaryIdList,
  intermediaryList,
  investorIdList,
  investorNameList,
} from 'src/app/investments/edit-investments.service';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        year: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        day: parseInt(date[0], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : '';
  }
}
@Component({
  selector: 'app-add-uploads',
  templateUrl: './add-uploads.component.html',
  styleUrls: ['./add-uploads.component.scss'],
  providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class AddUploadsComponent {
  intermediaryList= intermediaryList;
  investorNameList= investorNameList;
  investorIdList= investorIdList;
  intermediaryIdList= intermediaryIdList;

  investmentForm: any = FormGroup;
  getquestion: any = FormGroup;
  modelDate = '';
  isSubmitted = false;
  yourDateModel: Date = new Date();
  type: any;
  transaction: any;
  transaction_value: any = localStorage.getItem('transaction_value');
  ccy: any;
  type2: any;
  oldtransaction: any;
  oldinvestment: any;
  showField  = '';
  date  = '';
  investorType:any='';
  constructor(private formBuilder: FormBuilder, private http:HttpService,private spinner: NgxSpinnerService,private sharedService: UploadsComponent,public editUploadsService: EditUploadsService) {}

  ngOnInit(): void {
    this.investmentForm = this.editUploadsService.investmentForm
    this.getquestion = this.editUploadsService.getquestion
    this.type = this.editUploadsService.type
    this.transaction = this.editUploadsService.transaction
    this.ccy = this.editUploadsService.ccy
    this.type2 = this.editUploadsService.type2
    this.oldtransaction = this.editUploadsService.oldtransaction
    this.oldinvestment = this.editUploadsService.oldinvestment
    this.showField = this.sharedService.transactionType
    this.investorType = this.sharedService.investorType


  }

  get errorControl() {
    return this.investmentForm.controls;
  }
  onSubmit(){


    const fieldsToFormat = ['Bank_Date', 'Date', 'Months'];

    fieldsToFormat.forEach(field => {
      if (this.investmentForm.value[field]) {
        if (field === 'Months') {
          this.investmentForm.value[field] = moment(this.investmentForm.value[field]).format('MM');
        } else {
          this.investmentForm.value[field] = moment(this.investmentForm.value[field]).format('YYYY-MM-DD');
        }
      }
    });

    this.isSubmitted = true;
    console.log('clicked');
    this.investmentForm.controls['Transaction'].setValue(this.showField);
    console.log(this.investmentForm.value['Transaction']);
    if (this.investmentForm.invalid) {
      console.log(true);
      return;
    }else{
      console.log(false);
    }
    console.log('clicked1');
    const rawData = this.investmentForm.value;
    this.date = rawData['Date']['year'] + '-' + rawData['Date']['month'] + '-' + rawData['Date']['day'];
    rawData.Date = this.date;
    console.log(rawData);
    this.http.postData('investment',rawData).subscribe((response: any) => {
        console.log('Success:', response);
        this.closeModal()
        this.investmentForm.reset();
        Swal.fire({
          icon: "success",
          text: "Investment successfully added.",
          showConfirmButton: false,
          timer: 3000
        });
      },
      (error) => {
        console.error('Error:', error);
        // Handle errors
      }
    );
  }
  onOpenCalendar(container: any) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }


  ShowError(control: any, isSubmitted: any, content: any) {
      let html = '';
      if(isSubmitted && control.errors) {
        const icon = '<img alt="" src="assets/images/icon/error.png" class="error-icon">';
        if(control.errors?.required) {
          html += '<label class="error">'+icon+' '+content+' is Required</label>';
        }
        if(control.errors?.pattern) {
          html += '<label class="error">'+icon+' Numeric value only</label>';
        }
        if(control.errors?.invalidPercentage) {
          html += '<label class="error">'+icon+' percentage value only</label>';
        }
      }
      return html;
  }
  percentageValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (Validators.required(control) || Validators.pattern(/^(\d+(\.\d{1,2})?|\.\d{1,2})%$/)(control)) {
        return { 'invalidPercentage': { value } };
      }
      return null;
    };
  }

  closeModal() {
    this.isSubmitted = false;
    this.sharedService.isClassAdded = false;
    this.sharedService.transaction = '';
  }
}
