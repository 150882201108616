import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FormatWithCommasService {
  formatInputWithCommas(value: string) {
    let dots = value.split('.').length - 1;
    let formattedValue = value;
    dots = dots < 0 ? 0 : dots;
    if (dots > 1) {
      formattedValue = value.slice(0, -1);
    }
    formattedValue = formattedValue.replace(/,/g, '').replace(/\s/g, '');
    const parts = formattedValue.split('.');
    if (parts.length > 2) {
      formattedValue = parts[0] + '.' + parts.slice(1).join('');
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    formattedValue = parts.join('.');
    formattedValue = this.transform(formattedValue);
    return formattedValue;
  }

  transform(value: string): string {
    const parts = value.split('.');
    if (parts.length > 1) {
      const integerPart = parts[0];
      const decimalPart = parts[1].substring(0, 2);
      return integerPart + '.' + decimalPart;
    } else {
      return value;
    }
  }
}
