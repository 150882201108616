<div class="row p-1">
  <div class="col-lg-12 col-xl-12">
    <div class="card w-100">
      <div class="card">
        <form
          *ngIf="investmentForm"
          [formGroup]="investmentForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="row">
            <div class="width-236" >
              <div class="mt-3">
                <label for="intermediaryId" class="form-title">
                  <span class="required">*</span>Intermediary
                </label>
                <ng-select class="custom"
                           id="intermediaryId"
                           [style.flex]="1"
                           [items]="intermediaryIdList"
                           bindLabel="name"
                           bindValue="id"
                           placeholder="All"
                           formControlName="intermediaryId"
                           (change)="handleIntermediaryChange($event)">
                </ng-select>
              </div>
              <app-form-error-component [control]="investmentForm.controls['intermediaryId']" />
            </div>
            <div class="width-236">
              <div class="mt-3">
                <label for="investorId" class="form-title">
                  <span class="required">*</span> Investor
                </label>
              </div>
              <ng-select class="custom"
                         id="investorId"
                         [style.flex]="1"
                         [items]="investorGeneratedList"
                         bindLabel="name"
                         bindValue="id"
                         placeholder="All"
                         formControlName="investorId"
                         [searchFn]="ngSelectCustomSearch"
                         (change)="investorChange($event)"
              >
              </ng-select>
              <app-form-error-component [control]="investmentForm.controls['investorId']" />
            </div>

            <div class="col-12 bottom-fixed">
              <hr />
              <div class="d-flex justify-content-center mt-3">
                <button [disabled]="investmentForm.invalid" type="submit"
                        class="btn btn-light me-3 btn-custom-footer">
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-light btn-custom-footer"
                  data-dismiss="modal"
                  (click)="closeModal()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

