<div class="row">
  <div class="col-lg-12">
    <div class="card w-100">
      <div class="card ps-3">
        <form [formGroup]="etrForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-6">
              <div class="mt-3">
                <label for="originatorId" class="form-title"
                  > Originator
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.originatorId }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <ng-select
                 [items]="alloriginatorData"
                 bindLabel="name"
                 class="custom"
                 bindValue="id"
                 id="originatorId"
                 formControlName="originatorId"
                 >
                 <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear">
                   <div class="ng-value" *ngFor="let item of items">
                     <span class="ng-value-label">{{ item.name }}</span>
                   </div>
                 </ng-template>
               </ng-select>
              </div>
              <app-form-error-component [control]="etrForm.controls['originatorId']" />
            </div>
            <div class="col-md-6" *ngIf="this.etrName == 'd-ETR'  || this.etrName == ''">
              <div class="mt-3">
                <label for="debtorId" class="form-title"
                  > Debtor
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.debtorId }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <ng-select
                class="custom"
                [items]="allDebtorData"
                bindLabel="name"
                bindValue="id"
                id="debtorId"
                formControlName="debtorId"
                >
                <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>
              </div>
              <app-form-error-component [control]="etrForm.controls['debtorId']" />
            </div>
            <div class="col-md-6" *ngIf="this.etrName != 'd-ETR'">
              <div class="mt-3">
                <label for="creditorId" class="form-title"
                  > Creditor
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.creditorId }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <ng-select
                class="custom"
                [items]="allCreditorData"
                bindLabel="name"
                bindValue="id"
                id="creditorId"
                formControlName="creditorId"
                >
                <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>
              </div>
              <app-form-error-component [control]="etrForm.controls['creditorId']" />
            </div>
            <div class="col-md-6">
              <div class="mt-3">
                <label for="credebtorId" class="form-title"
                  > Credebtor
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.credebtorId }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <ng-select
                class="custom"
                [items]="allCredebtorData"
                bindLabel="name"
                bindValue="id"
                id="credebtorId"
                formControlName="credebtorId"
                >
                <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>
              </div>
              <app-form-error-component [control]="etrForm.controls['credebtorId']" />
            </div>

            <div class="col-md-6" *ngIf="this.etrName == 'a-ETR' || this.etrName == 'f-ETR' || this.etrName ==''">
              <div class="mt-3">
                <label for="leaseeId" class="form-title"
                  > Leasee
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.leaseeId }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <ng-select
                class="custom"
                [items]="allLeaseeData"
                bindLabel="name"
                bindValue="id"
                id="leaseeId"
                formControlName="leaseeId"
                >
                <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>
              </div>
              <app-form-error-component [control]="etrForm.controls['leaseeId']" />
            </div>
            <div class="width-65" *ngIf="this.etrName == 'a-ETR' || this.etrName == 'f-ETR' || this.etrName ==''">
              <div class="mt-3">
                <label for="batch" class="form-title"
                  > Batch
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.batch }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <select class="custom-form-select border-radius-100" id="batch" formControlName="batch">
                  <option *ngFor="let cc of allBatchData" [value]="cc.name">{{ cc.name }}</option>
                </select>
              </div>
              <app-form-error-component [control]="etrForm.controls['batch']" />
            </div>
            <div class="width-100" >
              <div class="mt-3">
                <label for="ledger" class="form-title"
                  > Ledger
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.ledger }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <select class="custom-form-select border-radius-100" id="ledger" formControlName="ledger">
                  <option *ngFor="let cc of ledgerOwnerData" [value]="cc.name">{{ cc.name }}</option>
                </select>
              </div>
              <app-form-error-component [control]="etrForm.controls['ledger']" />
            </div>
            <div class="width-65">
            <div class="mt-3">
              <label for="type" class="form-title"
                > Type
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.type }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="text"
                class="form-control text-left sd-width"
                formControlName="type"
                maxlength="10"
                id="type"
                [disabled]="true"
                [readOnly]="true"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['type']" />
            </div>
            
          

            <div class="width-100">
              <div class="mt-3">
                <label for="classification" class="form-title"
                  > Classification
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.classification }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <input
                  type="text"
                  class="form-control text-left ft-width"
                  placeholder=""
                  formControlName="classification"
                  id="classification"
                />
              </div>
              <app-form-error-component [control]="etrForm.controls['classification']" />
            </div>
            <div  class="width-60">
              <div class="mt-3">
                 <label for="ccy" class="form-title"> Ccy <span
                       class="tooltip-ng ms-0" placement="bottom" ngbTooltip="{{ tooltipData.ccy }}"><i
                          class="bi bi-question-circle"></i></span></label>
                          <select class="custom-form-select border-radius-100" id="ccy" formControlName="ccy">
                            <option *ngFor="let cc of ccy" [value]="cc.name">{{ cc.name }}</option>
                          </select>
              </div>
              <app-form-error-component [control]="etrForm.controls['ccy']" />
           </div>
            <div class="width-125">
              <div class="mt-3">
                <label for="issuedDate" class="form-title"
                  > Issued
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.issuedDate }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <div class="input-group">
                  <input
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                    name="name"
                    formControlName="issuedDate"
                    id="issuedDate"
                    ngbDatepicker
                    #d1="ngbDatepicker"
                    [readonly]="true"
                  />
                  <button
                    class="btn btn-outline-secondary btn-calendar-icon"
                    (click)="d1.toggle()"
                    type="button"
                  >
                    <img src="./assets/images/icon/calendar-icon.svg" />
                  </button>
                </div>
              </div>
              <app-form-error-component [control]="etrForm.controls['issuedDate']" />
            </div>
            <div class="width-111">
              <div class="mt-3">
                <label for="tradeId" class="form-title"
                  > Trade ID
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.tradeId }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <input
                  type="text"
                  class="form-control text-left sd-width"
                  placeholder=""
                  formControlName="tradeId"
                  id="tradeId"
                />
              </div>
              <app-form-error-component [control]="etrForm.controls['tradeId']" />
            </div>

            <div class="width-65">
              <div class="mt-3">
                <label for="ref" class="form-title"
                  > Ref
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.ref }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <input
                  type="text"
                  class="form-control text-left sd-width"
                  placeholder=""
                  formControlName="ref"
                  id="ref"
                />
              </div>
              <app-form-error-component [control]="etrForm.controls['ref']" />
            </div>
            <div class="width-60">
              <div class="mt-3">
                <label for="edso" class="form-title"
                  > EDSO
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.edso }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <input
                  type="number"
                  class="form-control text-left ft-width inputNumberText"
                  placeholder=""
                  formControlName="edso"
                  id="edso"
                />
              </div>
              <app-form-error-component [control]="etrForm.controls['edso']" />
            </div>
            <div class="width-80" *ngIf="this.etrName != ''">
              <div class="mt-3">
                <label for="tradeCommission" class="form-title"
                  > Charge
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.tradeCommission }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <input
                  type="text"
                  [onlyNumber]="true"
                  class="form-control text-left ft-width inputNumberText"
                  placeholder=""
                  formControlName="tradeCommission"
                  id="tradeCommission"
                />
              </div>
              <app-form-error-component [control]="etrForm.controls['tradeCommission']" />
            </div>
            <div class="width-125">
              <div class="mt-3">
                <label for="postedDate" class="form-title"
                  > Posted
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.postedDate }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <div class="input-group">
                  <input
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                    name="d1"
                    formControlName="postedDate"
                    id="postedDate"
                    ngbDatepicker
                    #d_PostedDate="ngbDatepicker"
                    [readonly]="true"
                  />
                  <button
                    class="btn btn-outline-secondary btn-calendar-icon"
                    (click)="d_PostedDate.toggle()"
                    type="button"
                  >
                    <img src="./assets/images/icon/calendar-icon.svg" />
                  </button>
                </div>
              </div>
              <app-form-error-component [control]="etrForm.controls['postedDate']" />
            </div>

            <div class="width-90" *ngIf="this.etrName != 'd-ETR'">
              <div class="mt-3">
                <label for="deposit" class="form-title"
                  > Deposit
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.deposit }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <input
                  type="text"
                  [onlyNumber]="true"
                  class="form-control text-left ft-width inputNumberText"
                  placeholder=""
                  formControlName="deposit"
                  id="deposit"
                />
              </div>
              <app-form-error-component [control]="etrForm.controls['deposit']" />
            </div>
            <div class="width-129">
              <div class="mt-3">
                <label for="tradedDate" class="form-title"
                  > Traded
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.tradedDate }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <div class="input-group">
                  <input
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                    name="d3"
                    formControlName="tradedDate"
                    ngbDatepicker
                    #d3="ngbDatepicker"
                    [readonly]="true"
                    id="tradedDate"
                  />
                  <button
                    class="btn btn-outline-secondary btn-calendar-icon"
                    (click)="d3.toggle()"
                    type="button"
                  >
                    <img src="./assets/images/icon/calendar-icon.svg" />
                  </button>
                </div>
              </div>
              <app-form-error-component [control]="etrForm.controls['tradedDate']" />
            </div>



           <div class="width-90">
            <div class="mt-3">
              <label for="faceValue" class="form-title"
                > Face Value
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.faceValue }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="text"
                [onlyNumber]="true"
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="faceValue"
                id="faceValue"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['faceValue']" />
          </div>

          <div class="width-80">
            <div class="mt-3">
              <label for="sellRate" class="form-title"
                > Sell Rate
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.sellRate }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <div class="input-container">
              <input
                type="text"
                percentNumber
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="sellRate"
                id="sellRate"
                maxlength="10"
              />
              <span>%</span>
            </div>
            </div>
            <app-form-error-component [control]="etrForm.controls['sellRate']" />
          </div>
          <div class="width-80">
            <div class="mt-3">
              <label for="sellType" class="form-title"
                > Sell Type
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.sellType }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="text"
                class="form-control text-left ft-width"
                placeholder=""
                formControlName="sellType"
                id="sellType"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['sellType']" />
          </div>
          <div class="width-70">
            <div class="mt-3">
              <label for="sellType" class="form-title"
                > No 180
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.disable180Days }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="text"
                onlyNumber
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="disable180Days"
                id="disable180Days"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['disable180Days']" />
          </div>
          <div class="width-80" *ngIf="this.etrName == 'a-ETR' || this.etrName == 'f-ETR' ||this.etrName == ''">
            <div class="mt-3">
              <label for="eot" class="form-title"
                > EOT
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.eot }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <div class="input-container">
              <input
                type="number"
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="eot"
                id="eot"
              />
              <span>%</span>
              </div>
              </div>
            <app-form-error-component [control]="etrForm.controls['eot']" />
          </div>
          <div class="width-105">
            <div class="mt-3">
              <label for="purchasedValue" class="form-title"  *ngIf="this.etrName == 'a-ETR' || this.etrName == 'f-ETR'"
                > PTV
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.purchasedValue }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <label for="purchasedValue" class="form-title"  *ngIf="this.etrName == 'b-ETR' || this.etrName == 'c-ETR'|| this.etrName == 'd-ETR'|| this.etrName == ''"
                > Purchased
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.purchasedValue }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <div class="d-flex">
              <input
                type="text"
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="purchasedValue"
                id="purchasedValue"
              /><span class="ms-1">%</span>
            </div>
            </div>
            <app-form-error-component  *ngIf="!show" [control]="etrForm.controls['purchasedValue']" />
          </div>
          <div class="width-110"  *ngIf="this.etrName == 'd-ETR' || this.etrName == ''">
            <div class="mt-3">
              <label for="ldcPremium" class="form-title"
                > LDC Premium
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.ldcPremium }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <div class="d-flex">
              <input
                type="text"
                onlyNumber
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="ldcPremium"
                id="ldcPremium"
              /><span class="ms-1">%</span>
            </div>
            </div>
            <app-form-error-component [control]="etrForm.controls['ldcPremium']" />
          </div>
          <div class="width-115"  *ngIf="this.etrName == 'd-ETR' || this.etrName == ''">
            <div class="mt-3">
              <label for="agentPremium" class="form-title"
                > Agent Premium
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.agentPremium }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <div class="d-flex">
              <input
                type="text"
                onlyNumber
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="agentPremium"
                id="agentPremium"
              /><span class="ms-1">%</span>
            </div>
            </div>
            <app-form-error-component [control]="etrForm.controls['agentPremium']" />
          </div>
          <div class="width-102">
            <div class="mt-3">
              <label for="tradedFx" class="form-title"
                > Traded Fx
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.tradedFx }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="number"
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="tradedFx"
                id="tradedFx"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['tradedFx']" />
          </div>

          <div class="col-md-4" *ngIf="this.etrName == ''">
            <div class="mt-3">
              <label for="tradeCommission" class="form-title"
                > Trade Commission
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.tradeCommission }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="text"
                class="form-control text-left ft-width"
                placeholder=""
                formControlName="tradeCommission"
                id="tradeCommission"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['tradeCommission']" />
          </div>

          <div class="width-129" *ngIf="this.etrName == 'd-ETR' || this.etrName == ''">
            <div class="mt-3">
              <label for="tb-fname" class="form-title"
                > Settled
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.settledDate }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="YYYY-MM-DD"
                  name="d4"
                  formControlName="settledDate"
                  ngbDatepicker
                  #d4="ngbDatepicker"
                  [readonly]="true"
                  id="settledDate"
                />
                <button
                  class="btn btn-outline-secondary btn-calendar-icon"
                  (click)="d4.toggle()"
                  type="button"
                >
                  <img src="./assets/images/icon/calendar-icon.svg" />
                </button>
              </div>
            </div>
            <app-form-error-component [control]="etrForm.controls['settledDate']" />
          </div>

          <div class="width-105" *ngIf="this.etrName == 'd-ETR' || this.etrName == ''">
            <div class="mt-3">
              <label for="settledFx" class="form-title"
                > Settled Fx
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.settledFx }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="text"
                onlyNumber
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="settledFx"
                id="settledFx"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['settledFx']" />
          </div>

          <div class="width-129">
            <div class="mt-3">
              <label for="closedDate" class="form-title"
                > Closed
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.closedDate }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <div class="input-group">
                <input
                  class="form-control"
                  placeholder="YYYY-MM-DD"
                  name="d5"
                  formControlName="closedDate"
                  ngbDatepicker
                  #d5="ngbDatepicker"
                  [readonly]="true"
                  id="closedDate"
                />
                <button
                  class="btn btn-outline-secondary btn-calendar-icon"
                  (click)="d5.toggle()"
                  type="button"
                >
                  <img src="./assets/images/icon/calendar-icon.svg" />
                </button>
              </div>
            </div>
            <app-form-error-component [control]="etrForm.controls['closedDate']" />
          </div>

          <div class="width-90">
            <div class="mt-3">
              <label for="closedFx" class="form-title"
                > Closed Fx
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.closedFx }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="text"
                onlyNumber
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="closedFx"
                id="closedFx"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['closedFx']" />
          </div>
          <div class="width-95" *ngIf="this.etrName == 'a-ETR' || this.etrName == 'f-ETR' ||this.etrName == ''">
            <div class="mt-3">
              <label for="unitPrice" class="form-title"
                > Unit Price
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.unitPrice }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="text"
                [onlyNumber]="true"
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="unitPrice"
                id="unitPrice"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['unitPrice']" />
          </div>
          <div class="width-102" *ngIf="this.etrName == 'a-ETR' || this.etrName == 'f-ETR' ||this.etrName == ''">
            <div class="mt-3">
              <label for="quantity" class="form-title"
                > Quantity
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.quantity }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="number"
                class="form-control text-left ft-width inputNumberText "
                placeholder=""
                formControlName="quantity"
                id="quantity"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['closedFx']" />
          </div>
          <div class="width-90">
            <div class="mt-3">
              <label for="idtkoi" class="form-title"
                > IDTKOI
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.idtkoi }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="text"
                class="form-control text-left ft-width"
                placeholder=""
                formControlName="idtkoi"
                id="idtkoi"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['idtkoi']" />
          </div>

          <div class="width-70" *ngIf="this.etrName == 'a-ETR' || this.etrName == 'f-ETR' ||this.etrName == ''">
            <div class="mt-3">
              <label for="months" class="form-title"
                > Months
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.months }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <input
                type="number"
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="months"
                id="months"
              />
            </div>
            <app-form-error-component [control]="etrForm.controls['months']" />
          </div>

          <div class="width-110" *ngIf="this.etrName == 'a' || this.etrName == 'f' ||this.etrName == ''">
            <div class="mt-3">
              <label for="vatIncluded" class="form-title"
                > Vat Included
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.vatIncluded }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <div class="input-container">
              <input
                type="number"
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="vatIncluded"
                id="vatIncluded"
              />
              <span>%</span>
            </div>
            </div>
            <app-form-error-component [control]="etrForm.controls['vatIncluded']" />
          </div>
          <div class="width-130" *ngIf="this.etrName == 'a' || this.etrName == 'f' ||this.etrName == ''">
            <div class="mt-3">
              <label for="vatOnPurchases" class="form-title"
                > Vat On Purchases
                <span
                  class="tooltip-ng ms-0"
                  placement="bottom"
                  ngbTooltip="{{ tooltipData.vatOnPurchases }}"
                  ><i class="bi bi-question-circle"></i></span
              ></label>
              <div class="input-container">
              <input
                type="number"
                class="form-control text-left ft-width inputNumberText"
                placeholder=""
                formControlName="vatOnPurchases"
                id="vatOnPurchases"
              />
              <span>%</span>
            </div>
            </div>
            <app-form-error-component [control]="etrForm.controls['vatOnPurchases']" />
          </div>

            <div class="width-129">
              <div class="mt-3">
                <label for="pausedAt" class="form-title"
                  > Paused
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.pausedAt }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <div class="input-group">
                  <input
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                    name="d10"
                    formControlName="pausedAt"
                    ngbDatepicker
                    #d10="ngbDatepicker"
                    [readonly]="true"
                    id="pausedAt"
                  />
                  <button
                    class="btn btn-outline-secondary btn-calendar-icon"
                    (click)="d10.toggle()"
                    type="button"
                  >
                    <img src="./assets/images/icon/calendar-icon.svg" />
                  </button>
                </div>
              </div>
              <app-form-error-component [control]="etrForm.controls['pausedAt']" />
            </div>
            <div class="width-129">
              <div class="mt-3">
                <label for="resumedAt" class="form-title"
                  > Resumed
                  <span
                    class="tooltip-ng ms-0"
                    placement="bottom"
                    ngbTooltip="{{ tooltipData.resumedAt }}"
                    ><i class="bi bi-question-circle"></i></span
                ></label>
                <div class="input-group">
                  <input
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                    name="d11"
                    formControlName="resumedAt"
                    ngbDatepicker
                    #d11="ngbDatepicker"
                    [readonly]="true"
                    id="resumedAt"
                  />
                  <button
                    class="btn btn-outline-secondary btn-calendar-icon"
                    (click)="d11.toggle()"
                    type="button"
                  >
                    <img src="./assets/images/icon/calendar-icon.svg" />
                  </button>
                </div>
              </div>
              <app-form-error-component [control]="etrForm.controls['resumedAt']" />
            </div>
            <div class="col-md-12">
              <div class="mt-3">
                <label for="tb-fname" class="form-title"> Notes </label>
                <textarea type="text" class="form-control" placeholder="Write here notes..." formControlName="notes" row="3" maxlength="512"> </textarea>
              </div>
              <app-form-error-component [control]="errorControl['notes']"></app-form-error-component>
            </div>
            <div class="col-md-12">
              <div class="mt-3">
                <label for="tb-fname" class="form-title"> Comments </label>
                <textarea type="text" class="form-control" placeholder="Write here comments..." formControlName="comments" row="3" maxlength="512"> </textarea>
              </div>
              <app-form-error-component [control]="errorControl['comments']"></app-form-error-component>
            </div>
            <div class="col-12 bottom-fixed">
              <hr />
              <div class="d-flex justify-content-center mt-3">
                <button [disabled]="etrForm.invalid" type="submit" class="btn btn-light me-3 btn-custom-footer">
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-light btn-custom-footer"
                  data-dismiss="modal"
                  (click)="closeModal()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


