import Swal, { SweetAlertResult } from 'sweetalert2';

export function successMessage(text: string): void {
  Swal.fire({
    text,
    icon: 'success',
    showConfirmButton: false,
    timer: 3500,
  });
}

export function errorMessage(text: string) {
  Swal.fire({
    text,
    icon: 'error',
    showConfirmButton: true,
    confirmButtonText: 'Ok',
  });
}

export function confirmMessage(text?: string, confirmButtonText?: string): Promise<SweetAlertResult> {
  return Swal.fire({
    title: 'Are you sure?',
    text: text || 'You will be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#505050',
    cancelButtonColor: '#505050',
    confirmButtonText: confirmButtonText || 'Delete',
  });
}
