import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment/moment';
import { ParseDate } from '../../../shared/helpers/utils';

@Injectable({ providedIn: 'root' })
export class ETRFormProvider {
  etrForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  initETRForm(data?: any): FormGroup {
    return (this.etrForm = this.fb.group({
      id: [data?.id],
      originatorId: [data?.originatorId],
      credebtorId: [data?.credebtorId],
      leaseeId: [data?.leaseeId],
      creditorId: [data?.creditorId],
      debtorId: [data?.debtorId],
      batch: [data?.batch],
      ledger: [data?.ledger],
      type: [data?.type],
      tradeId: [data?.tradeId],
      issuedDate: [data?.issuedDate ? ParseDate(data?.issuedDate) : null],
      ref: [data?.ref],
      postedDate: [data?.postedDate ? ParseDate(data?.postedDate) : null],
      ccy: [data?.ccy],
      faceValue: [data?.faceValue],
      purchasedValue: [data?.purchasedValue],
      classification: [data?.classification],
      tradedDate: [data?.tradedDate ? ParseDate(data?.tradedDate) : null],
      edso: [data?.edso],
      deposit: [data?.deposit],
      sellRate: [data?.sellRate],
      sellType: [data?.sellType],
      ldcPremium: [data?.ldcPremium],
      agentPremium: [data?.agentPremium],
      disable180Days: [data?.disable180Days],
      tradedFx: [data?.tradedFx],
      tradeCommission: [data?.tradeCommission],
      settledDate: [data?.settledDate ? ParseDate(data?.settledDate) : null],
      closedDate: [data?.closedDate ? ParseDate(data?.closedDate) : null],
      closedFx: [data?.closedFx],
      settledFx: [data?.settledFx],
      idtkoi: [data?.idtkoi],
      notes: [data?.notes],
      comments: [data?.comments],
      pausedAt: [data?.pausedAt ? ParseDate(data?.pausedAt) : null],
      resumedAt: [data?.resumedAt ? ParseDate(data?.resumedAt) : null],
      unitPrice: [data?.unitPrice],
      quantity: [data?.quantity],
      months: [data?.months],
      eot: [data?.eot],
      vatIncluded: [data?.vatIncluded],
      vatOnPurchases: [data?.vatOnPurchases],
    }));
  }

  private changeDigits(param: number) {
    return param.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

