import { Component, OnDestroy, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { EditInvestmentsService } from '../edit-investments.service';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, GridReadyEvent, ModuleRegistry, SideBarDef } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

import { COL_DEFS } from './table.columns.const';
import { FormGroup } from '@angular/forms';
import { CLUTCH, CURRENCIES } from '../../shared/constants/helpers.const';
import { generateDateRange } from '../../shared/helpers/generate-date-range';
import { InvestmentsFormProvider } from '../investments-form-provider';
import { Summary } from '../../shared/interfaces/bank-response.interface';
import { CalculatePinnedInvestments } from '../../shared/helpers/calculate-pinned-investments';
import { SummaryKeyInvestments } from '../../shared/enums/common.enum';
import {
  Investment,
  InvestmentsResponseInterface,
} from '../../shared/interfaces/investments-response.interface';
import { InvestmentsQueryParams } from '../../shared/interfaces/investments-query-params';
import { queryCleanerModel } from '../../shared/helpers/query-cleaner-model';
import { INVESTMENTS_PARAMS_KEYS } from './investments.helper';
import { InvestmentsHttpService } from '../common/services/investments-http.service';
import { setNormal, setPrinterFriendly } from '../../shared/helpers/print';
import { successMessage } from '../../shared/helpers/messages-popup';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { SelectItem } from 'src/app/shared/interfaces/select-item.interface';
import { TradeHttpService } from 'src/app/trade/services/trade-http.service';
// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule, MultiFilterModule, SetFilterModule, MenuModule, ClipboardModule, FiltersToolPanelModule, ColumnsToolPanelModule]);

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss'],
})
export class InvestmentsComponent implements OnInit, OnDestroy {
  currentRecord: Investment;
  investmentForm: FormGroup;
  savedFilterModel: any = localStorage.getItem('savedFilterModel');
  sortingState: any = localStorage.getItem('sortingState');
  isLoading = false;
  accounts: any = [];
  selectedClutch = '';
  selectedCcy = '';
  rowData!: any[];
  ccyData: Array<{ id: string; name: string }> = CURRENCIES;
  colDefs: ColDef[] = COL_DEFS(this);
  defaultColDef: ColDef = {
    //flex: 1,
    resizable: true,
    floatingFilter: true,
  };
  paginationPageSize = 50;
  totalPage = 0;
  totalRecords = 0;
  recordsPerPage = 100;
  currentPageIndex = 0;
  totalPageString: string;
  totalRecordsDisplay = '0';
  gridApi: any;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  dateRange: any = null;
  currentFilter = 'All';
  financialList: any;
  selectedFinancialYear = '';
  clutchList: Array<{ id: string; name: string }> = CLUTCH;
  isShowAddEditInvestments: boolean;
  isShowAddEditInvestor: boolean;
  selectedMonth = '';
  monthList: any = generateDateRange();
  flag = false;
  selectedRecordPerPage = '50';
  pinnedRowData!: Summary;
  params: any;
  queryParams: Partial<InvestmentsQueryParams> = {
    pageIndex: 0,
    perPage: 50,
    sort: ['id'],
  };
  defaultQueryParams: Partial<InvestmentsQueryParams> = { ...this.queryParams };
  toggleSettings = {
    labels: {
      unchecked: 'Person',
      checked: 'Organization',
    },
    values: {
      unchecked: 'person',
      checked: 'organisation',
    },
    switchColor: {
      checked: '#fff',
      unchecked: '#fff',
    },
    color: {
      unchecked: '#d4d5db',
      checked: '#d4d5db',
    },
    fontColor: {
      checked: 'black',
      unchecked: 'black',
    },
  };
  isPersonInvestor = true;
  dialogWidth = '596px';
  multipleSelected: boolean;
  selectedRows: any;

  private target: any;
  sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
    position: 'right',
  };
  private intermediaryFilterList: any;
  private intermediaryFilterListSnapshot: any;
  private intermediaryNameFilterList: any;
  private intermediaryNameFilterListSnapshot: any;
  private investorFilterList: any = [];
  private investorFilterListSnapshot: any = [];
  private investorNameFilterList: any = [];
  private investorNameFilterListSnapshot: any = [];
  private filterState: any;
  trusteeList: SelectItem[] = [];
  constructor(
    public editInvestmentsService: EditInvestmentsService,
    private investmentsFormProvider: InvestmentsFormProvider,
    private investmentsHttpService: InvestmentsHttpService,
    private tradeTransactionsHttpService: TradeHttpService,
  ) {}

  ngOnInit(): void {
    this.investmentForm = this.investmentsFormProvider.investmentForm;
    this.financialList = this.editInvestmentsService.Year;
    this.getInvestmentFiltersData();
    this.getCurrenciesData();
    this.getTrusteesList();
  }

  private getInvestmentFiltersData(): void {
    this.investmentsHttpService.getInvestmentsFilterData().subscribe((investmentsFilterData) => {
      //ledger
      this.colDefs[1].floatingFilterComponentParams.selectArray = [];
      this.colDefs[1].floatingFilterComponentParams.selectArray = [
        ...(investmentsFilterData.ledgers.map((ledger) => ({
          id: ledger.name,
          name: ledger.name,
        })) || []),
      ];

      // intermediaryID column
      this.intermediaryFilterList = [
        ...(investmentsFilterData.intermediariesWithInvestors.map((intermediary) => ({
          id: intermediary.id,
          name: `${intermediary.id} - ${intermediary.name}`,
          intermediaryName: intermediary.name,
          investors: intermediary.investors,
        })) || []),
      ];
      this.colDefs[3].floatingFilterComponentParams.selectArray = [];
      this.colDefs[3].floatingFilterComponentParams.selectArray = this.intermediaryFilterList;
      this.intermediaryFilterListSnapshot = [...this.intermediaryFilterList];

      // intermediaryName column
      this.intermediaryNameFilterList = [
        ...(investmentsFilterData.intermediariesWithInvestors
          .sort((a, b) => {
            const nameA = a.name?.toUpperCase();
            const nameB = b.name?.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
          .map((intermediary) => ({
            id: intermediary.name,
            name: intermediary.name,
            investors: intermediary.investors,
            intermediaryID: intermediary.id,
          })) || []),
      ];
      this.colDefs[4].floatingFilterComponentParams.selectArray = [];
      this.colDefs[4].floatingFilterComponentParams.selectArray = this.intermediaryNameFilterList;
      this.intermediaryNameFilterListSnapshot = [...this.intermediaryNameFilterList];

      // investorId column
      investmentsFilterData.intermediariesWithInvestors.forEach((currentValue: any) => {
        const investors = currentValue.investors.map((item: any) => {
          return {
            ...item,
            id: item.id,
            name: `${item.id} - ${item.name}`,
            intermediary: { name: currentValue.name, id: currentValue.id },
            investorName: item.name,
          };
        });
        this.investorFilterList = [...this.investorFilterList, ...investors];
      });
      this.investorFilterList.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      this.colDefs[5].floatingFilterComponentParams.selectArray = [];
      this.colDefs[5].floatingFilterComponentParams.selectArray = this.investorFilterList;
      this.investorFilterListSnapshot = [...this.investorFilterList];

      investmentsFilterData.intermediariesWithInvestors.forEach((currentValue: any) => {
        const investorsName = currentValue.investors.map((item: any) => {
          return {
            ...item,
            id: item.name,
            name: `${item.id} - ${item.name}`,
            intermediary: { name: currentValue.name, id: currentValue.id },
            investorId: item.id,
          };
        });
        this.investorNameFilterList = [...this.investorNameFilterList, ...investorsName];
      });
      this.investorNameFilterList.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.colDefs[6].floatingFilterComponentParams.selectArray = [];
      this.colDefs[6].floatingFilterComponentParams.selectArray = this.investorNameFilterList;
      this.investorNameFilterListSnapshot = [...this.investorNameFilterList];

      // transactionType column
      this.colDefs[18].floatingFilterComponentParams.selectArray = [];
      this.colDefs[18].floatingFilterComponentParams.selectArray = [
        ...(investmentsFilterData.transactionTypes.map((transactionType) => ({
          id: transactionType,
          name: transactionType,
        })) || []),
      ];

      // product column
      this.colDefs[2].floatingFilterComponentParams.selectArray = [];
      this.colDefs[2].floatingFilterComponentParams.selectArray = [
        ...(investmentsFilterData.products.map((product) => ({
          id: product,
          name: product,
        })) || []),
      ];

      // type column
      this.colDefs[12].floatingFilterComponentParams.selectArray = [];
      this.colDefs[12].floatingFilterComponentParams.selectArray = [
        ...(investmentsFilterData.types.map((type) => ({
          id: type,
          name: type,
        })) || []),
      ];

      // brand column
      this.colDefs[22].floatingFilterComponentParams.selectArray = [];
      this.colDefs[22].floatingFilterComponentParams.selectArray = [
        ...(investmentsFilterData.brands.map((brand) => ({
          id: brand,
          name: brand,
        })) || []),
      ];

      // history column
      this.colDefs[23].floatingFilterComponentParams.selectArray = [];
      this.colDefs[23].floatingFilterComponentParams.selectArray = [
        ...(investmentsFilterData.histories.map((history) => ({
          id: history,
          name: history,
        })) || []),
      ];
      this.setColDefs();
    })
    this.setColDefs();
  }

  private getTrusteesList(): void {
    // trustee column
    this.colDefs[17].floatingFilterComponentParams.selectArray = [];
    this.tradeTransactionsHttpService.getTrustees().subscribe((response: any) => {
      this.trusteeList = response.map((value: SelectItem) => ({
        id: value.id,
        name: value.name,
      }));
      this.colDefs[17].floatingFilterComponentParams.selectArray = this.trusteeList;
    });
  }

  private setLedgerID(selectedLedgerId: string) {
    this.colDefs[1].floatingFilterComponentParams.defaultValue = selectedLedgerId;
  }

  private setIntermediaryID(selectedIntermediaryID: string) {
    this.colDefs[3].floatingFilterComponentParams.defaultValue = selectedIntermediaryID;
  }

  private setIntermediary(selectedIntermediary: string) {
    this.colDefs[4].floatingFilterComponentParams.defaultValue = selectedIntermediary;
  }

  private setInvestorId(selectedInvestorId: string) {
    this.colDefs[5].floatingFilterComponentParams.defaultValue = selectedInvestorId;
  }

  private setInvestorName(selectedInvestorName: string) {
    this.colDefs[6].floatingFilterComponentParams.defaultValue = selectedInvestorName;
  }

  private setTransactionType(selectedTransactionType: string) {
    this.colDefs[18].floatingFilterComponentParams.defaultValue = selectedTransactionType;
  }

  private setCcy(selectedCcy: string) {
    this.colDefs[8].floatingFilterComponentParams.defaultValue = selectedCcy;
  }

  private setProduct(selectedProduct: string) {
    this.colDefs[2].floatingFilterComponentParams.defaultValue = selectedProduct;
  }

  private setType(selectedType: string) {
    this.colDefs[12].floatingFilterComponentParams.defaultValue = selectedType;
  }

  private setBrand(selectedBrand: string) {
    this.colDefs[22].floatingFilterComponentParams.defaultValue = selectedBrand;
  }

  private setHistory(selectedHistory: string) {
    this.colDefs[23].floatingFilterComponentParams.defaultValue = selectedHistory;
  }

  private setSavings(selectedSaving: string) {
    this.colDefs[16].floatingFilterComponentParams.defaultValue = selectedSaving;
  }

  private setTrustee(selectedTrustee: string) {
    this.colDefs[17].floatingFilterComponentParams.defaultValue = selectedTrustee;
  }

  private getCurrenciesData(): void {
    this.investmentsHttpService.getCurrencyFilterData().subscribe((currencyFilterData) => {
      this.colDefs[8].floatingFilterComponentParams.selectArray = [];
      this.colDefs[8].floatingFilterComponentParams.selectArray = [
        ...(currencyFilterData.map((currencyFilterData) => ({
          id: currencyFilterData,
          name: currencyFilterData,
        })) || []),
      ];
      this.setColDefs();
    });
  }

  private setColDefs() {
    setTimeout(() => {
      this.gridApi?.setGridOption('columnDefs', this.colDefs);
      this.gridApi?.refreshHeader();
      this.setColumnsSettings();
    }, );
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.params = params;
    this.getBottomData();
    this.onGridRefresh();
  }

  onGridRefresh() {
    this.rowData=[];
    this.multipleSelected = false;
    this.getInvestmentsData(this.queryParams);
  }

  onFilterChanged(event: any, type?: string): void {
    event.api.showLoadingOverlay();
    if (event.api != undefined) {
      const filterModel = this.gridApi.getFilterModel();
      const currentColumnId = event.columns[0].colId;
      this.queryParams = queryCleanerModel(this.queryParams, filterModel, currentColumnId, INVESTMENTS_PARAMS_KEYS);

      if (filterModel?.intermediaryId?.filter) this.setIntermediaryID(filterModel?.intermediaryId?.filter || '');
      if (filterModel?.ledgerName?.filter) this.setLedgerID(filterModel?.ledgerName?.filter || '');
      if (filterModel?.intermediaryName?.filter) this.setIntermediary(filterModel?.intermediaryName?.filter || '');
      if (filterModel?.investorId?.filter) this.setInvestorId(filterModel?.investorId?.filter || '');
      if (filterModel?.investorName?.filter) this.setInvestorName(filterModel?.investorName?.filter || '');
      if (filterModel?.transactionType?.filter) this.setTransactionType(filterModel?.transactionType?.filter || '');
      if (filterModel?.ccy?.filter) this.setCcy(filterModel?.ccy?.filter || '');
      if (filterModel?.product?.filter) this.setProduct(filterModel?.product?.filter || '');
      if (filterModel?.type?.filter) this.setType(filterModel?.type?.filter || '');
      if (filterModel?.brand?.filter) this.setBrand(filterModel?.brand?.filter || '');
      if (filterModel?.history?.filter) this.setHistory(filterModel?.history?.filter || '');
      if (filterModel?.savings?.filter) this.setSavings(filterModel?.savings?.filter || '');
      if (filterModel?.trusteeId?.filter) this.setTrustee(filterModel?.trusteeId?.filter || '');

      if (currentColumnId === 'intermediaryId') {
        const selectedIntermediary = this.intermediaryFilterList.find((intermediary: any) => intermediary.id === filterModel[currentColumnId]?.filter);
        this.colDefs[4].floatingFilterComponentParams.disabled = !!filterModel?.intermediaryId?.filter;
        delete this.queryParams['intermediaryName'];
        if (selectedIntermediary) {
         this.setInvestorFilterData(selectedIntermediary.investors);
       }
      }

      if (currentColumnId === 'intermediaryName') {
        const selectedIntermediary = this.intermediaryNameFilterList.find((intermediary: any) => intermediary.name === filterModel[currentColumnId]?.filter);
        this.colDefs[3].floatingFilterComponentParams.disabled = !!filterModel?.intermediaryName?.filter;
        delete this.queryParams['intermediaryId'];
        if (selectedIntermediary) {
          this.setInvestorFilterData(selectedIntermediary.investors);
        }
      }

      if (currentColumnId === 'investorId') {
        const selectedInvestor = this.investorFilterList.find((investor: any) => investor.id === filterModel[currentColumnId]?.filter);
        this.colDefs[6].floatingFilterComponentParams.disabled = !!filterModel?.investorId?.filter;
        delete this.queryParams['investorName'];
        if (selectedInvestor && selectedInvestor.intermediary) {
          this.setIntermediaryFilterData(selectedInvestor.intermediary);
        }
      }

      if (currentColumnId === 'investorName') {
        const selectedInvestor = this.investorNameFilterList.find((investor: any) => investor.name === filterModel[currentColumnId]?.filter);
        this.colDefs[5].floatingFilterComponentParams.disabled = !!filterModel?.investorName?.filter;
        delete this.queryParams['investorId'];
        if (selectedInvestor && selectedInvestor.intermediary) {
          this.setIntermediaryFilterData(selectedInvestor.intermediary);
        }
      }
      if (!Object.prototype.hasOwnProperty.call(this.queryParams,'intermediaryId') &&
          !Object.prototype.hasOwnProperty.call(this.queryParams,'intermediaryName') &&
          !Object.prototype.hasOwnProperty.call(this.queryParams,'investorId') &&
          !Object.prototype.hasOwnProperty.call(this.queryParams,'investorName')) {
        this.colDefs[3].floatingFilterComponentParams.selectArray = this.intermediaryFilterListSnapshot;
        this.colDefs[4].floatingFilterComponentParams.selectArray = this.intermediaryNameFilterListSnapshot;
        this.colDefs[5].floatingFilterComponentParams.selectArray = this.investorFilterListSnapshot;
        this.colDefs[6].floatingFilterComponentParams.selectArray = this.investorNameFilterListSnapshot;
        this.setColDefs();
      }

      this.getBottomData();
      this.getDataOnFilterChange();
      this.setColDefs();
    }
  }

  private setInvestorFilterData(investors: any) {
    if (Object.prototype.hasOwnProperty.call(this.queryParams,'investorId')) {
      const isFounded = investors.find((investor: any) => investor.id === this.queryParams.investorId);
      if (!isFounded) {
        delete this.queryParams.investorId;
      }
    }

    if (Object.prototype.hasOwnProperty.call(this.queryParams,'investorName')) {
      const isFounded = investors.find((investor: any) => investor.name === this.queryParams.investorName);
      if (!isFounded) {
        delete this.queryParams.investorName;
      }
    }

    this.colDefs[5].floatingFilterComponentParams.selectArray = [
      ...(investors.map((investor: { id: any; name: string }) => ({
        id: investor.id,
        name: `${investor.id} - ${investor.name}`,
      })) || []),
    ];
    this.colDefs[6].floatingFilterComponentParams.selectArray = [
      ...(investors.map((investor: { id: any; name: string }) => ({
        id: investor.name,
        name: `${investor.id} - ${investor.name}`,
      }))
        .sort((a: any, b: any) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }) || []),
    ];
    this.setColDefs();
  }

  private setIntermediaryFilterData(intermediary: any) {
    this.colDefs[3].floatingFilterComponentParams.selectArray = [
      {
        id: intermediary.id,
        name: `${intermediary.id} - ${intermediary.name}`,
      },
    ];
    this.colDefs[4].floatingFilterComponentParams.selectArray = [
      {
        id: intermediary.name,
        name: intermediary.name,
      },
    ];
    this.setColDefs();
  }

  private getDataOnFilterChange(): void {
    this.getInvestmentsData(this.queryParams);
  }

  generatePinnedBottomData(key: keyof Summary, type?: SummaryKeyInvestments) {
    // generate a row-data with null values
    const result: any = {};
    this.gridApi.getAllGridColumns().forEach((item: any) => {
      result[item.colId] = null;
    });
    this.target = CalculatePinnedInvestments(this.pinnedRowData, this.target, result, key, type);
    return this.target;
  }

  getInvestmentsData(requestParams: Partial<InvestmentsQueryParams>) {
    this.isLoading = true;
    this.investmentsHttpService.getInvestmentsData(requestParams).subscribe({
      next: (response: InvestmentsResponseInterface) => {
        this.currentPageIndex = response.meta.pageIndex;
        this.totalRecords = response.meta.total;
        this.recordsPerPage = response.meta.perPage;
        this.totalPage = Math.ceil(this.totalRecords / this.recordsPerPage);
        this.totalPageString = this.totalPage.toLocaleString('en-US');
        this.totalRecordsDisplay = this.totalRecords.toLocaleString('en-US');
        let snostartno = 1;
        if (this.currentPageIndex != 0) {
          snostartno = (this.currentPageIndex + 1) * (this.queryParams?.perPage || 0) - ((this.queryParams.perPage || 0) - 1);
        }
        for (let i = 0; i < response.items.length; i++) {
          response.items[i].no = i + snostartno;
          response.items[i].fx_rate = this.generateFxRateValue(response.items[i]);
          response.items[i].eurFaceValue = response.items[i].faceValue * (response.items[i].fx_rate || 1);
        }
        this.rowData = [...response.items];
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error:', error);
        this.isLoading = false;
        this.gridApi.hideOverlay();
      },
    });
  }

  private generateFxRateValue(value: any) {
    switch (value.ccy) {
      case 'EUR':
        return 1;
      case 'GBP':
        return value.fxRate?.gbp;
      case 'USD':
        return value.fxRate?.usd;
    }
  }

  getBottomData() {
    this.investmentsHttpService.getInvestmentsSummary(this.queryParams).subscribe((value) => {
      for (const valueElement of Object.values(value)) {
        for (const valueElementElement of valueElement) {
          if (valueElementElement.key === "months") {
            valueElementElement.value = +valueElementElement.value.toFixed(2)
          }
        }
      }
      this.pinnedRowData = value;
      this.setPinnedData();
    });
  }

  changePageSelection(pageIndex: number) {
    if (pageIndex >= 0 && pageIndex < this.totalPage) {
      this.currentPageIndex = pageIndex;
      this.queryParams['pageIndex'] = this.currentPageIndex;
      this.onGridRefresh();
    }
  }

  setPinnedData(key: keyof Summary = 'sum', type?: SummaryKeyInvestments): void {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData(key, type);
      this.gridApi.setGridOption('pinnedBottomRowData', [pinnedBottomData]);
    }, 500);
  }

  resetFilters() {
    this.queryParams = this.defaultQueryParams;
    const listDate = INVESTMENTS_PARAMS_KEYS;
    listDate.forEach((listDate) => {
      const filterComponent = this.gridApi.getFilterInstance(listDate);
      if (filterComponent) {
        filterComponent.setModel(null);
      }
    });
    this.gridApi.resetColumnState();
    this.gridApi.refreshHeader();
    this.gridApi.setQuickFilter('');
    this.colDefs[3].floatingFilterComponentParams.disabled = false;
    this.colDefs[4].floatingFilterComponentParams.disabled = false;
    this.colDefs[5].floatingFilterComponentParams.disabled = false;
    this.colDefs[6].floatingFilterComponentParams.disabled = false;
    this.colDefs[3].floatingFilterComponentParams.selectArray = this.intermediaryFilterListSnapshot;
    this.colDefs[4].floatingFilterComponentParams.selectArray = this.intermediaryNameFilterListSnapshot;
    this.colDefs[5].floatingFilterComponentParams.selectArray = this.investorFilterListSnapshot;
    this.colDefs[6].floatingFilterComponentParams.selectArray = this.investorNameFilterListSnapshot;
    this.selectedMonth = '';
    this.selectedFinancialYear = '';
    this.gridApi.refreshCells();
    this.setColDefs()
  }

  openModal(data?: any) {
    this.currentRecord = data == 'add' ? null : data;
    this.multipleSelected = false;
    if (data == 'add Investor') {
      this.isShowAddEditInvestor = true;
    } else {
      this.selectedRows = this.gridApi.getSelectedRows();
      if (this.selectedRows.length > 1) {
        if (this.allSameIntermediaryId(this.selectedRows)){
          this.multipleSelected = true;
          return;
        }
        Swal.fire({
          title: 'Edit Statements',
          text: "Selected Investments has different Intermediary",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
        })
        console.log(this.allSameIntermediaryId(this.selectedRows))
      } else {
        this.multipleSelected = false;
        this.isShowAddEditInvestments = true;
      }
    }
  }

  private allSameIntermediaryId(items: any[]) {
    if (items.length === 0) return true;
    const firstIntermediaryId = items[0].intermediaryId;

    return items.every(item => item.intermediaryId === firstIntermediaryId);
  }

  closeModal() {
    this.isShowAddEditInvestments = false;
  }

  closeBulkEditModal() {
    this.multipleSelected = false;
  }

  closeModalInvestor() {
    this.isShowAddEditInvestor = false;
    this.isPersonInvestor = true;
  }

  delete(data: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.investmentsHttpService.deleteData('investments', data.id).subscribe({
          next: (response: any) => {
            successMessage(`Investment successfully deleted.`);
            this.getBottomData();
            this.getInvestmentsData(this.queryParams);
          },
          error: (error) => {
            console.error('Error:', error);
            this.isLoading = false;
          },
        });
      }
    });
  }

  filterChanged(filter: Partial<InvestmentsQueryParams>): void {
    this.queryParams = { ...this.queryParams, ...filter };
    this.paginationPageSize = filter.perPage!;
    this.getBottomData();
    this.onGridRefresh();
  }

  refreshPage(): void {
    this.resetFilters();
  }

  changePinnedRowType = (event: { value: keyof Summary; column: SummaryKeyInvestments }) => {
    this.setPinnedData(event.value, event.column);
  };

  saveFilterData(event: any, type?: string) {
    const sortingState = this.gridApi.getColumnState();
    localStorage.setItem('sortingState', JSON.stringify(sortingState));
    this.savedFilterModel = this.gridApi.getFilterModel();
    localStorage.setItem('savedFilterModel', JSON.stringify(this.savedFilterModel));
    const sortColumn = sortingState.filter((item: any) => item.sort != null)[0];
    if (sortColumn) {
      event.api.showLoadingOverlay();
      if (sortColumn.sort == 'asc') this.queryParams['sort'] = sortColumn.colId;
      else this.queryParams['sort'] = '-' + sortColumn.colId;
      this.getDataOnFilterChange();
    }

    localStorage.setItem('sortingState', JSON.stringify(sortingState));
    this.savedFilterModel = this.gridApi.getFilterModel();
    localStorage.setItem('savedFilterModel', JSON.stringify(this.savedFilterModel));
    if (event.api != undefined) {
      event.api.refreshCells();
    }

    Swal.fire({
      icon: 'success',
      text: 'Layout successfully saved.',
      showConfirmButton: false,
      timer: 3000,
    });
  }

  onSortChanged(event?: any, type?: string) {
    const sortingState = this.gridApi.getColumnState();
    this.savedFilterModel = this.gridApi.getFilterModel();
    const sortColumn = sortingState.filter((item: any) => item.sort != null)[0];
    if (sortColumn) {
      event?.api?.showLoadingOverlay();
      if (sortColumn.sort == 'asc') this.queryParams['sort'] = sortColumn.colId === 'week' ? 'date' : sortColumn.colId;
      else this.queryParams['sort'] = '-' + (sortColumn.colId === 'week' ? 'date' : sortColumn.colId);

      this.getDataOnFilterChange();
    } else {
      this.queryParams.sort = this.defaultQueryParams.sort;
      this.getDataOnFilterChange();
    }
  }

  exportData() {
    this.gridApi.exportDataAsExcel();
  }

  onPrint() {
    setPrinterFriendly(this.gridApi);
    setTimeout(() => {
      print();
      setNormal(this.gridApi);
    }, 2000);
  }

  private setColumnsSettings(): void {
    const columnsSettingsAsString: any = localStorage.getItem('investmentsColumnsSettings');
    const columnsSettingsParsed: any = JSON.parse(columnsSettingsAsString);
    if (this.gridApi) {
    this.gridApi.applyColumnState({
      state: columnsSettingsParsed,
      applyOrder: true,
    });
  }
  }

  onColumnVisibilityChanged(event: any) {
    setTimeout(() => {
      localStorage.setItem('investmentsColumnsSettings', JSON.stringify(this.gridApi.getColumnState()));
    }, 500);
  }

  toggleChange(event: any): void {
    this.isPersonInvestor = event === 'person';
  }

  ngOnDestroy(): void {
    localStorage.removeItem('investmentsColumnsSettings');
  }
}
