import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  title = 'Angular 14 - MSAL v2 Quickstart Sample';
  isIframe = false;
  loginDisplay = false;
  loginText = "Login"
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(this.isLoggedIn()){
      this.router.navigate(['/dashboard']);
    }
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  login() {
    this.loginText= "Please Wait ..."
    this.router.navigate(['/dashboard']);
    return true;
    // if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //   if (this.msalGuardConfig.authRequest) {
    //     this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
    //       .subscribe((response: AuthenticationResult) => {
    //         this.authService.instance.setActiveAccount(response.account);
    //       });
    //   } else {
        
    //     this.authService.loginPopup()
    //       .subscribe((response: AuthenticationResult) => {
    //         this.authService.instance.setActiveAccount(response.account);
    //       });
    //   }
    // } else {
      if (this.msalGuardConfig.authRequest) {
        console.log("--------------  returing popup login ---------------------<br>")
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest).subscribe((response: AuthenticationResult) => {
          console.log("--------------  returing popup after ---------------------<br>")
          console.log(response)
          let res:any = response
          localStorage.setItem("token", res.accessToken)
          localStorage.setItem("name", res.account?.name)
          this.router.navigate(['/dashboard']);
          //this.authService.instance.setActiveAccount(response.account);
        });
      } else {
        console.log("--------------  returing here before login ---------------------<br>")
        this.authService.loginRedirect();
        console.log("--------------  returing here after login ---------------------<br>")
      }
    //}
  }

  logout() {
    //if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    // } else {
    //   this.authService.logoutPopup({
    //     postLogoutRedirectUri: "/",
    //   });
    // }
  }

  private isLoggedIn(): boolean {
    // Check if the user is logged in by verifying the presence of the 'token' in local storage
    return !!localStorage.getItem('token');
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}