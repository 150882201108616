<div *ngIf="isLoading" class="loader_main">
  <div class="loader">Loading...</div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="iframe-container">
  <div class="card-body header-title">
    <div class="row">
      <div class="col-md-8 d-flex justify-content-between" >
        <p class="card-title ps-1">Charts</p>
      </div>
    </div>

    <div class="ps-4 me-2 mt-3 gap-3 d-flex align-items-center custom-dd-div">
      <div class="width-20p">
        <label class="form-title">Investor</label>
        <ng-select class="custom-form-select" [multiple]="false" [closeOnSelect]="true" [selectableGroup]="false" placeholder="All">
          <ng-option *ngFor="let item of investorsIds" [value]="item.id">{{item.id }} - {{ item.name}}</ng-option>
        </ng-select>
      </div>

      <div class="width-6p">
        <label for="type" class="form-title"> Type</label>
        <select class="custom-form-select form-select-g" id="type" [(ngModel)]="selectedType">
          <option value="All">All</option>
          <option *ngFor="let item of tradeTransTypes" [value]="item.id">{{ item.name }}</option>
        </select>
      </div>

      <div class="width-7p">
        <label for="period" class="form-title"> Period</label>
        <select class="custom-form-select form-select-g" id="period" [(ngModel)]="selectedPeriod" (ngModelChange)="onPeriodChange()">
          <option *ngFor="let item of periods" [value]="item.id">{{ item.name }}</option>
        </select>
      </div>

      <div class="width-6p">
        <label for="type" class="form-title"> ETR</label>
        <select class="custom-form-select form-select-g" id="type" [(ngModel)]="selectedETR">
          <option value="All">All</option>
          <option *ngFor="let item of etrTypes" [value]="item.id">{{ item.name }}</option>
        </select>
      </div>

      <div class="width-20p">
        <label class="form-title">Credebtor</label>
        <ng-select class="custom-form-select" [multiple]="false" [closeOnSelect]="true" [selectableGroup]="false" placeholder="All">
          <ng-option *ngFor="let item of credebtorsIds" [value]="item.id">{{item.id }} - {{ item.name}}</ng-option>
        </ng-select>
      </div>

      <div class="width-20p">
        <label class="form-title">Originator ID:</label>
        <ng-select class="custom-form-select"  [multiple]="false" [closeOnSelect]="true" [selectableGroup]="false"
          placeholder="All">
          <ng-option *ngFor="let item of originatorIds" [value]="item.id">{{item.id }} - {{ item.name}}</ng-option>
        </ng-select>
      </div>

    </div>
    <div class="row pl-5">
      <ag-charts [options]="multipleLineOptions" class="multiple-line-chart"></ag-charts>
    </div>
    <div class="row pl-5">
      <div class="time-periods">
        <a [class.active]="selectedTimePeriod === '1M'" (click)="onSelectTimePeriod('1M')">1M</a>
        <a [class.active]="selectedTimePeriod === '6M'" (click)="onSelectTimePeriod('6M')">6M</a>
        <a [class.active]="selectedTimePeriod === '1Y'" (click)="onSelectTimePeriod('1Y')">1Y</a>
        <a [class.active]="selectedTimePeriod === '5Y'" (click)="onSelectTimePeriod('5Y')">5Y</a>
        <a [class.active]="selectedTimePeriod === '10Y'" (click)="onSelectTimePeriod('10Y')">10Y</a>
        <a [class.active]="selectedTimePeriod === '25Y'" (click)="onSelectTimePeriod('25Y')">25Y</a>
        <a [class.active]="selectedTimePeriod === 'All'" (click)="onSelectTimePeriod('All')">All</a>
      </div>
    </div>
  </div>