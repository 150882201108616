import { Component, OnInit } from '@angular/core';
import { AgChartOptions } from 'ag-charts-community';
import { TradeTransactionsQueryParams } from '../shared/interfaces/trade-transactions-query-params';
import { SelectItem } from '../shared/interfaces/select-item.interface';
import { EntitiesHttpService } from '../entities/entities-http.service';
import { forkJoin } from 'rxjs';
import { EntityQueryParams } from '../shared/interfaces/entity-query-params';
import { TradeHttpService } from '../trade/services/trade-http.service';
import { InvestmentsHttpService } from '../investments/common/services/investments-http.service';
import { demoDataMonth, demoDataQuater, demoDataWeek, demoDataYear } from './chart-static-data';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnInit {
  isLoading: boolean = false;
  multipleLineOptions: AgChartOptions | any;
  queryParamsEntity: Partial<EntityQueryParams> = {
    pageIndex: 0,
    perPage: 10000,
  };
  originatorIds: Array<SelectItem> = [];
  investorsIds: Array<SelectItem> = [];
  credebtorsIds: Array<SelectItem> = [];
  etrTypes: Array<SelectItem> = [];
  tradeTransTypes: Array<SelectItem> = [];
  periods: Array<SelectItem> = [
    { id: 'Week', name: 'Week' },
    { id: 'Month', name: 'Month' },
    { id: 'Quarter', name: 'Quarter' },
    { id: 'Year', name: 'Year' },
  ];
  selectedPeriod: string = 'Quarter';
  selectedType: string = 'All';
  selectedETR: string = 'All';
  selectedTimePeriod: string = 'All';
  demoDataQuater = demoDataQuater;
  demoDataWeek = demoDataWeek;
  demoDataMonth = demoDataMonth;
  demoDataYear = demoDataYear;

  constructor(
    private entityService: EntitiesHttpService,
    private tradeTransactionsHttpService: TradeHttpService,
    private investmentsHttpService: InvestmentsHttpService,
  ) {
    setTimeout(() => {
      this.getLineChartByQuarter();
    }, 300);
  }

  ngOnInit() {
    this.getDropDownValues();
    this.initMultipleLineOptions();
  }

  initMultipleLineOptions() {
    this.multipleLineOptions = {
      data: [],
      zoom: {
        enabled: true,
        axes: 'xy',
      },
      series: [
        { type: 'line', xKey: 'quarter', yKey: 'aETR', stacked: true, yName: 'a-ETR' },
        { type: 'line', xKey: 'quarter', yKey: 'bETR', stacked: true, yName: 'b-ETR' },
        { type: 'line', xKey: 'quarter', yKey: 'cETR', stacked: true, yName: 'c-ETR' },
        { type: 'line', xKey: 'quarter', yKey: 'dETR', stacked: true, yName: 'd-ETR' },
        { type: 'line', xKey: 'quarter', yKey: 'fETR', stacked: true, yName: 'f-ETR' },
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          interval: {
            minSpacing: 80,
            maxSpacing: 120,
          },
        },
        {
          type: 'log',
          position: 'left',
          interval: {
            minSpacing: 80,
            maxSpacing: 120,
          },
          label: {
            formatter: (params: any) => {
              const value = Math.abs(Number(params.value));
              const decimal = 2;
              if (value >= 1.0e9) {
                return (value / 1.0e9).toFixed(decimal) + ' b';
              } else if (value >= 1.0e6) {
                return (value / 1.0e6).toFixed(decimal) + ' m';
              } else if (value >= 1.0e3) {
                return (value / 1.0e3).toFixed(decimal) + ' k';
              } else {
                return value.toFixed(decimal);
              }
            },
          },
          gridLine: {
            style: [
              {
                stroke: 'lightblack',
                lineDash: [1, 1],
              },
            ],
          },
        },
      ],

      legend: {
        position: 'right',
      },
    };
  }
  private getLineChartByQuarter(): void {
    this.multipleLineOptions = {
      ...this.multipleLineOptions,
      data: this.demoDataQuater,
    };
  }
  getDropDownValues() {
    this.isLoading = true;
    forkJoin({
      etrsTypes: this.tradeTransactionsHttpService.getEtrFilterDistinctValues(),
      entities: this.entityService.getEntities({ ...this.queryParamsEntity, role: 'Investor' }),
      originators: this.entityService.getEntityOriginators(),
      credebtors: this.entityService.getEntityCredebtors(),
      tradeTransTypes: this.investmentsHttpService.getInvestmentsFilterData(),
    }).subscribe({
      next: ({ etrsTypes, entities, originators, credebtors, tradeTransTypes }) => {
        this.etrTypes = etrsTypes.types
          .filter((value) => value !== 'ETR')
          .map((value) => ({
            id: value,
            name: value,
          }));
        this.tradeTransTypes = tradeTransTypes.types.map((value) => ({
          id: value,
          name: value,
        }));
        this.investorsIds = entities.items.map((entity) => ({
          id: entity.entityRoles[0].id.toString(),
          name: entity.name,
        }));
        this.originatorIds = originators;
        this.credebtorsIds = credebtors;
        this.isLoading = false;
      },
    });
  }

  onPeriodChange() {
    switch (this.selectedPeriod) {
      case 'Week':
        this.multipleLineOptions = {
          ...this.multipleLineOptions,
          data: this.demoDataWeek,
        };
        break;
      case 'Month':
        this.multipleLineOptions = {
          ...this.multipleLineOptions,
          data: this.demoDataMonth,
        };
        break;
      case 'Year':
        this.multipleLineOptions = {
          ...this.multipleLineOptions,
          data: this.demoDataYear,
        };
        break;
      default:
        this.multipleLineOptions = {
          ...this.multipleLineOptions,
          data: this.demoDataQuater,
        };
        break;
    }
  }

  onSelectTimePeriod(period: string) {
    this.selectedTimePeriod = period;
  }
}
