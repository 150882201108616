import { ColDef } from '@ag-grid-community/core';
import { dateFilterParams } from '../../shared/helpers/date-comperator';
import { PercentValueFormatter4 } from '../../shared/helpers/percent-value-formatter';
import { numberValueFormatter2 } from '../../shared/helpers/number-value-formatter';
import { SummaryKeyFxrate } from '../../shared/enums/common.enum';
import { CustomPinnedRowRendererComponent } from '../../shared/components/custom-pinned-row';
import { FxrateComponent } from './fxrate.component';
import { ShowCellRendererComponent } from './show-cell-renderer.component';

export const COL_DEFS: (component: FxrateComponent) => ColDef[] = (component) => [
  {
    headerName: 'No',
    field: 'no',
    width: 50,
    colId: 'no',
    hide: false,
    suppressMovable: true,
    filterParams: {
      maxNumConditions: 1,
    },
    sortable: false,
    floatingFilter: false,
    valueFormatter: (params) => {
      const originalValue = params.node?.rowPinned;
      if (originalValue) {
        return '';
      }
      return params.value;
    },
  },
  {
    headerName: 'Date',
    field: 'date',
    filter: 'agDateColumnFilter',
    colId: 'date',
    filterParams: dateFilterParams,
    valueFormatter: (params) => {
      const originalValue = params.value;
      let formattedValue = '';
      if (originalValue == '~') {
        formattedValue = '';
      } else {
        formattedValue = originalValue;
      }
      return formattedValue;
    },
    width: 105,
    floatingFilter: true,
    cellClass: 'ag-center-aligned-cell',
    cellRendererSelector: cellRendererSelector(component, SummaryKeyFxrate.BuyRate),
  },
  {
    headerName: 'Demand',
    field: 'demand',
    filter: 'agNumberColumnFilter',
    width: 65,
    colId: 'demand',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    flex: 1,
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'Fixed',
    field: 'fixed',
    filter: 'agNumberColumnFilter',
    width: 65,
    colId: 'fixed',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    flex: 1,
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'Term',
    field: 'term',
    filter: 'agNumberColumnFilter',
    width: 65,
    colId: 'term',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    flex: 1,
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'Buy Rate',
    field: 'buyRate',
    filter: 'agNumberColumnFilter',
    width: 70,
    colId: 'buyRate',
    cellClass: 'ag-center-aligned-cell',
    flex: 1,
    valueFormatter: PercentValueFormatter4,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'GBP',
    field: 'gbp',
    width: 70,
    colId: 'gbp',
    filter: 'agNumberColumnFilter',
    cellClass: 'ag-right-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    flex: 1,
    valueFormatter: numberValueFormatter2,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'USD',
    field: 'usd',
    width: 70,
    colId: 'usd',
    filter: 'agNumberColumnFilter',
    cellClass: (params) => {
      if (params.data.bank !== params.value) {
        return 'ag-right-aligned-cell';
      }
      return 'ag-right-aligned-cell';
    },
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    flex: 1,
    valueFormatter: numberValueFormatter2,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'US10Y',
    field: 'us10y',
    colId: 'us10y',
    filter: 'agNumberColumnFilter',
    width: 70,
    cellClass: 'ag-center-aligned-cell',
    flex: 1,
    valueFormatter: PercentValueFormatter4,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'US3Y',
    field: 'us3y',
    colId: 'us3y',
    filter: 'agNumberColumnFilter',
    width: 70,
    cellClass: 'ag-center-aligned-cell',
    flex: 1,
    valueFormatter: PercentValueFormatter4,
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'EURIBOR',
    field: 'eonia',
    filter: 'agNumberColumnFilter',
    width: 75,
    colId: 'eonia',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    flex: 1,
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'LIBOR',
    field: 'libor',
    filter: 'agNumberColumnFilter',
    width: 65,
    colId: 'libor',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    flex: 1,
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    headerName: 'SOFR',
    field: 'obfr',
    filter: 'agNumberColumnFilter',
    width: 65,
    colId: 'obfr',
    cellClass: 'ag-center-aligned-cell',
    filterParams: {
      filterOptions: [
        {
          displayKey: 'all',
          displayName: 'all',
          predicate: () => true,
        },
      ],
    },
    flex: 1,
    valueFormatter: PercentValueFormatter4,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    // cellRenderer: tooltipRenderer,
  },
  {
    field: 'Action',
    pinned: 'right',
    cellRenderer: ShowCellRendererComponent,
    cellDataType: false,
    width: 60,
    flex: 1,
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    suppressMenu: false,
    suppressMovable: true,
    sortable: false,
  },
];

function cellRendererSelector(component: FxrateComponent, column: SummaryKeyFxrate) {
    return (params: any) => {
        if (params.node.rowPinned) {
            return {
                component: CustomPinnedRowRendererComponent,
                params: {
                    setTotal: component.changePinnedRowType,
                    column,
                    value: params.node.value,
                    values: [
                        { id: 'sum', name: 'Sum' },
                        { id: 'avg', name: 'Average' },
                        { id: 'max', name: 'Maximum' },
                        { id: 'min', name: 'Minimum' },
                    ],
                },
            };
        } else {
            return undefined;
        }
    };
}
