import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Investment } from '../../shared/interfaces/investments-response.interface';
import { InvestmentsHttpService } from '../common/services/investments-http.service';
import { InvestmentsFormProvider } from '../investments-form-provider';
import * as moment from 'moment/moment';
import { ConvertISONumberToNormal, getFormData } from '../../shared/helpers/utils';
import Swal from 'sweetalert2';
import { SimpleNumberValueFormatter } from '../../shared/helpers/number-formatter.helper';
import { PercentFormatter } from '../../shared/helpers/percent-formatter.helper';

@Component({
  selector: 'app-investment-bulk-update',
  templateUrl: './investment-bulk-update.component.html',
  styleUrls: ['./investment-bulk-update.component.scss'],
})
export class InvestmentBulkUpdateComponent {
  investmentForm: FormGroup;
  // todo need remove when can get from backend side
  tooltipData = {
    intermediaryId: 'Intermediary ID',
    intermediaryName: 'Intermediary Name',
    investorId: 'Investor ID',
    investorName: 'Investor Name',
  };
  isSubmitted = false;
  intermediaryIdList: { id: string; name: string }[];
  investorIdListSnapshot: { id: string; name: string; intermediary: { id: string; name: string } }[];
  intermidiaryListSnapshot: { id: string; name: string }[];
  investorGeneratedList: { id: string; name: string; intermediary: { id: string; name: string } }[] = [];

  @Input() selectedRows: Investment[];
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  @Output() createUpdateEvent = new EventEmitter<boolean>();

  constructor(
    private investmentsHttpService: InvestmentsHttpService,
    private investmentsFormProvider: InvestmentsFormProvider,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.investmentForm = this.initForm();
    this.getEntityList();
  }

  initForm(): FormGroup {
    return (this.investmentForm = this.fb.group({
      intermediaryId: [null, [Validators.required]],
      investorId: [null, [Validators.required]],
    }));
  }

  private getEntityList(): void {
    this.investmentsHttpService.getIntermediariesWithInvestors().subscribe((intermediariesWithInvestors) => {
      this.intermediaryIdList = [
        ...(intermediariesWithInvestors.map((value: any) => ({
          id: value.id,
          name: `${value.id}-${value.name}`,
          nameForSearch: `${value.id}-${value.name}`,
          investors: value.investors,
        })) || []),
      ];
      this.intermediaryIdList.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.intermidiaryListSnapshot = this.intermediaryIdList;

      intermediariesWithInvestors.forEach((currentValue: any) => {
        currentValue.investors.forEach((item: any) => {
          item.nameForSearch = `${item.id}-${item.name}`;
          item.name = `${item.id}-${item.name}`;
          item.intermediary = { name: currentValue.name, id: currentValue.id };
        });
        this.investorGeneratedList = [...this.investorGeneratedList, ...currentValue.investors];
        this.investorGeneratedList.sort((a: any, b: any) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      });

      this.investorIdListSnapshot = [...this.investorGeneratedList];
    });
  }

  handleIntermediaryChange(event: any): void {
    if (!event) {
      this.intermediaryIdList = this.intermidiaryListSnapshot;
      this.investorGeneratedList = this.investorIdListSnapshot;
    } else {
      this.investorGeneratedList = event?.investors || this.investorIdListSnapshot;
    }
  }

  onSubmit() {
    const requestData = {
      ids: this.selectedRows.map((item) => item.id),
      intermediaryId: this.investmentForm.value.intermediaryId,
      investorId: this.investmentForm.value.investorId,
    };
    this.doUpdate(requestData);
  }

  doUpdate(data: any): void {
    this.investmentsHttpService.bulkUpdateInvestments(data).subscribe({
      next: (response: any) => {
        this.closeModal();
        Swal.fire({
          icon: 'success',
          text: 'Investments successfully updated.',
          showConfirmButton: false,
          timer: 5000,
        });
        this.investmentForm.reset();
        this.createUpdateEvent.emit(true);
      },
    });
  }

  closeModal() {
    this.isSubmitted = false;
    this.closeDialogEvent.emit(false);
  }

  investorChange(event: any) {
    if (!event) {
      this.investorGeneratedList = this.investorIdListSnapshot;
      this.intermediaryIdList = this.intermidiaryListSnapshot;
    } else {
      this.intermediaryIdList = event ? [event.intermediary] : this.intermidiaryListSnapshot;
      if (event) {
        this.investmentForm.get('intermediaryId')?.patchValue(event.intermediary.id);
      }
    }
  }

  ngSelectCustomSearch(term: string, item: any): any {
    return item.nameForSearch.indexOf(term) > -1;
  }
}
