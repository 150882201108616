import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { InvestmentsComponent } from './investments.component';

@Component({
  selector: 'colour-cell',
  standalone: true,
  imports: [CommonModule],
  template: `<div *ngIf="!pinCheck" class="action">
    <button type="button" class="btn btn-link btn-custom-link" (click)="openModalEdit(params)">
      <img src="./assets/images/icon/edit.svg" />
    </button>
    <button type="button" class="btn btn-link btn-custom-link" (click)="DeleteInvest(params)">
      <img src="./assets/images/icon/trash.svg" />
    </button>
  </div>`,
})
export class ShowCellRenderer implements ICellRendererComp {
  constructor(private sharedService: InvestmentsComponent) {}
  ui: any;
  params!: ICellRendererParams;
  public pinCheck: boolean = false;
  agInit(params: ICellRendererParams) {
    this.params = params.data;
    if (params.node.rowPinned) {
      this.pinCheck = true;
    }
  }

  openModalEdit(res: any) {
    this.sharedService.openModal(res);
  }
  DeleteInvest(id: any) {
    this.sharedService.delete(id);
  }

  getGui() {
    return this.ui;
  }

  refresh() {
    return false;
  }
}
