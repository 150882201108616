import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadsComponent } from '../uploads/uploads.component';
import Swal from 'sweetalert2';
import { EditUploadsService } from '../edit-uploads.service';
import { ETRFormProvider } from './etr/etr-form-provider';
import { CURRENCIES, TOOLTIP_DATA_ETR } from 'src/app/shared/constants/helpers.const';
import { ETRResponseInterface } from 'src/app/shared/interfaces/etr-response.interface';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TradeHttpService } from 'src/app/trade/services/trade-http.service';
import { SelectItem } from 'src/app/shared/interfaces/select-item.interface';
import { AdvancedNumberValueFormatter } from 'src/app/shared/helpers/number-formatter.helper';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        year: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        day: parseInt(date[0], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : '';
  }
}
@Component({
  selector: 'app-add-etr',
  templateUrl: './add-etr.component.html',
  styleUrls: ['./add-etr.component.scss'],
  providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class AddEtrComponent implements OnInit{
  etrForm: any = FormGroup;
  tooltipData = TOOLTIP_DATA_ETR;
  isSubmitted = false;
  ccy = CURRENCIES;
  etrName: string = '';
  alloriginatorData: SelectItem[] = [];
  allCredebtorData: SelectItem[] = [];
  allCreditorData: SelectItem[] = [];
  allLeaseeData: SelectItem[] = [];
  allBatchData: SelectItem[] = [];
  allDebtorData: SelectItem[] = [];
  @Input() etrData: ETRResponseInterface;
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  @Output() createUpdateEvent = new EventEmitter<boolean>();
  ledgerOwnerData: SelectItem[] = [];
  show: boolean=false;
  private unsubscribe$ = new Subject<void>();
  constructor(private formBuilder: FormBuilder,
    private http:HttpService,
    private spinner: NgxSpinnerService,
    private sharedService: UploadsComponent,
    public editUploadsService: EditUploadsService,
    private etrFormProvider: ETRFormProvider,
    private tradeTransactionsHttpService: TradeHttpService,) {}

  ngOnInit(): void {
    this.etrForm = this.etrFormProvider.initETRForm(this.etrData);
    this.etrForm.valueChanges.subscribe(() => {
      console.log(this.etrForm.errors);
    });
    this.etrName = this.sharedService.etrName;
    this.etrForm.patchValue({ type: this.etrName });
    this.getDistinctValues();
    this.subscribeToValueChanges()
    this.subscribeToMonthChanges()
  }

  get errorControl() {
    return this.etrForm.controls;
  }
  onSubmit() {
    const fieldsToFormat = ['issuedDate', 'postedDate', 'tradedDate', 'pausedAt', 'settledDate', 'resumedAt', 'closedDate'];
    const rawData = { ...this.etrForm.value };
    fieldsToFormat.forEach((field) => {
      if (this.etrForm.value[field]) {
        const dateMoment = moment(`${rawData[field].year}-${rawData[field].month}-${rawData[field].day}`, 'YYYY-MM-DD');
        rawData[field] = moment(dateMoment).format('YYYY-MM-DD');
      }
    });
    this.isSubmitted = true;
    if (this.etrForm.invalid) {
      return;
    }
    this.http.postData('etr', rawData).subscribe((response: any) => {
      this.closeModal()
      this.etrForm.reset();
      Swal.fire({
        icon: "success",
        text: "ETR successfully added.",
        showConfirmButton: false,
        timer: 3000
      });
    },
    );
  }

  closeModal() {
    this.isSubmitted = false;
    this.closeDialogEvent.emit(false);
  }

   getDistinctValues() {
      this.tradeTransactionsHttpService.getEtrFilterDistinctValues().subscribe((response) => {
        this.ledgerOwnerData = response.ledgerNames.map((value) => ({
          id: value,
          name: value,
        }));
        this.ledgerOwnerData.sort((a, b) => a.name.localeCompare(b.name));
      })
      this.tradeTransactionsHttpService.getEtrFilterDistinctValues().subscribe((response: any) => {
        this.allBatchData = response.batches.map((value: any) => ({
            id: value,
            name: value,
          }));
          this.allBatchData.sort((a, b) => a.name.localeCompare(b.name));
        })
      this.tradeTransactionsHttpService.getOriginators().subscribe((response: any) => {
        this.alloriginatorData = response.map((value: any) => ({
          id: value.id,
          name: `${value.id}-${value.name}`,
        }));
        this.alloriginatorData.sort((a, b) => a.name.localeCompare(b.name));
      });
      this.tradeTransactionsHttpService.getCreditors().subscribe((response: any) => {
        this.allCreditorData = response.map((value: any) => ({
          id: value.id,
          name: `${value.id}-${value.name}`,
        }));
        this.allCreditorData.sort((a, b) => a.name.localeCompare(b.name));
      });
      this.tradeTransactionsHttpService.getCredebtors().subscribe((response: any) => {
        this.allCredebtorData = response.map((value: any) => ({
          id: value.id,
          name: `${value.id}-${value.name}`,
        }));
        this.allCredebtorData.sort((a, b) => a.name.localeCompare(b.name));
      });

      this.tradeTransactionsHttpService.getLeasees().subscribe((response: any) => {
        this.allLeaseeData = response.map((value: any) => ({
          id: value.id,
          name: `${value.id}-${value.name}`,
        }));
      });
      this.tradeTransactionsHttpService.getDebtors().subscribe((response: any) => {
        this.allDebtorData = response.map((value: any) => ({
          id: value.id,
          name: `${value.id}-${value.name}`,
        }));
        this.allDebtorData.sort((a, b) => a.name.localeCompare(b.name));
      });
    }

    private subscribeToValueChanges(): void {
      const fields = [
        { field: 'sellRate', decimal: 3 },
        { field: 'purchasedValue', decimal: 3 },
        { field: 'ldcPremium', decimal: 3 },
        { field: 'agentPremium', decimal: 3 },
        { field: 'tradedFx', decimal: 4 },
        { field: 'closedFx', decimal: 4 },
        { field: 'settledFx', decimal: 4 },
        { field: 'eot', decimal: 3 },
        { field: 'vatOnPurchases', decimal: 2 },
      ];
      fields.forEach(({ field, decimal }) => {
        this.etrForm.get(field)?.valueChanges.pipe(
          debounceTime(500),
          takeUntil(this.unsubscribe$)
        ).subscribe((value: any) => {
          const formattedValue = AdvancedNumberValueFormatter({ number: value, decimal });
          this.etrForm.get(field)?.patchValue(formattedValue, { emitEvent: false });
        });
      });
    }

    subscribeToMonthChanges(): void {
      this.etrForm.get('months')?.valueChanges.pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((value: any) => {
        if (value) {
          const trimmedValue = value.toString().slice(0, 2);
          this.etrForm.get('months')?.patchValue(trimmedValue, { emitEvent: false });
        }
      });
    }

    ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
}
