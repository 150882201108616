import { Component } from '@angular/core';
import { IFloatingFilter, IFloatingFilterParams, NumberFilterModel } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';

export interface SliderFloatingFilterParams extends IFloatingFilterParams {
  value: number;
  maxValue: number;
  selectArray: any;
  selectTitle: string;
  defaultValue: string;
  callBackFunctionfilter: any;
}

@Component({
  selector: 'app-custom-select-search-filter',
  templateUrl: './custom-select-search-filter.component.html',
  styleUrls: ['./custom-select-search-filter.component.scss'],
})
export class CustomSelectSearchFilterComponent implements IFloatingFilter, AgFrameworkComponent<SliderFloatingFilterParams> {
  maxValue!: number;
  currentValue: any = null;
  selectArray!: any;
  selectTitle: string = '';
  defaultValue: string = '';
  itemsList: { id: string; name: string; nameForSearch: string }[];
  isDisabled: boolean;

  private params!: SliderFloatingFilterParams;

  agInit(params: SliderFloatingFilterParams & { disabled: boolean; clearable: boolean }): void {
    this.params = params;
    this.maxValue = this.params.maxValue;
    this.currentValue = this.params.defaultValue;
    this.selectArray = this.params.selectArray;
    this.selectTitle = this.params.selectTitle;
    this.isDisabled = params.disabled;
    this.itemsList = [
      { id: '', name: 'All' },
      ...this.params.selectArray.map((item: { id: string; name: string }) => {
        return { id: item.id, name: item.name };
      }),
    ];
  }

  valueChanged(event: { id: string; name: string }): void {
    const valueToUse = event ? event.id : '';
    if (valueToUse == 'Blanks') {
      this.params.parentFilterInstance(function (instance) {
        instance.onFloatingFilterChanged('equals', valueToUse);
      });
    } else {
      this.params.parentFilterInstance(function (instance) {
        instance.onFloatingFilterChanged('equals', valueToUse);
      });
    }
  }

  onParentModelChanged(parentModel: NumberFilterModel): void {
    if (!parentModel) {
      this.currentValue = null;
    }
  }
}
