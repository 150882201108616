<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
            <a class="sidebar-link waves-effect waves-dark"  [routerLink]="!!sidebarnavItem.path ? [sidebarnavItem.path] : null"
                [class.has-arrow]="sidebarnavItem.class === 'collapse'"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink" (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <!--<i [ngClass]="[sidebarnavItem.icon]"></i>-->
                <img src="{{sidebarnavItem.image}}" class="menu-image">
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>



            <ul aria-expanded="false" class="collapse first-level" [class.in]="showMenu === sidebarnavItem.title">
                <li class="sidebar-item" *ngFor="let submenu of sidebarnavItem.submenu">
                  <a href="javascript:void(0)" class="sidebar-link"
                     routerLinkActive="active"
                     [routerLink]="!!submenu.path ? [submenu.path] : null"

                  (click)="addExpandSubClass(submenu.title)"
                  >
                    <img src="{{submenu.image}}" class="menu-image">
                    <span class="hide-menu"> {{submenu.title}} </span>
                  </a>
                </li>

              </ul>
        </li>
        <!--<li class="text-center p-3 upgrade-btn">
            <a href="https://www.wrappixel.com/templates/ample-admin-angular/" class="btn d-block w-100 btn-danger">Upgrade to Pro</a>
        </li>-->
    </ul>
</nav>
