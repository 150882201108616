import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import * as moment from 'moment';
import { PAGE_SIZES } from '../../constants/helpers.const';
import { TradeSharedService } from 'src/app/trade/services/trade-shared.service';
import { EntitiesSharedService } from 'src/app/entities/entities-shared.service';
import { SelectItem } from '../../interfaces/select-item.interface';
import { generateDateRange, generateYearRange } from '../../helpers/generate-date-range';

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss'],
})
export class GridFilterComponent implements OnInit, OnDestroy, OnChanges {
  pageSizes = PAGE_SIZES;
  form: FormGroup = this.fb.group({
    bank: [],
    account: [],
    clutch: [''],
    ccy: [''],
    search: [],
    range: [],
    month: [],
    year: [],
    startDate: [],
    tradeId: [],
    originatorId: [],
    ledger: [],
    endDate: [],
    perPage: [50],
    type: [''],
    role: [''],
  });

  private subscriptions = new Subscription();
  private _defaultClutch: string;
  private _defaultAccount: string;
  private _defaultBank: string;
  private _selectedMonth: string;
  private _selectedFinancialYear: string;

  @Input() set selectedClutch(clutch: string) {
    this.form.patchValue({ clutch }, { emitEvent: false, onlySelf: true });
  }

  @Input() set defaultClutch(clutch: string) {
    this._defaultClutch = clutch;
    this.form.patchValue({ clutch }, { emitEvent: false, onlySelf: true });
  }

  @Input() set selectedAccount(account: string) {
    const bank = this.accountsList?.find((val) => val.account_no === account)?.bank_code;
    this.form.patchValue({ account, bank }, { emitEvent: true, onlySelf: true });
  }

  @Input() set defaultAccount(account: string) {
    this._defaultAccount = account;
    this._defaultBank = this.accountsList?.find((val) => val.account_no === account)?.bank_code!;
    this.form.patchValue({ account, bank: this._defaultBank }, { emitEvent: false, onlySelf: true });
  }
  @Input() clutchList: Array<SelectItem> = [];
  @Input() tradeIds: Array<SelectItem> = [];
  @Input() ccyList: Array<SelectItem> = [];
  @Input() selectedCcy: string = '';
  @Input() currentFilter: string = '';

  @Input() set selectedMonth(month: string) {
    if (!month) {
      return;
    }
    this._selectedMonth = month;
    this.onShowData('Month');
    this.form.patchValue({ month }, { emitEvent: false, onlySelf: true });
  }
  get selectedMonth(): string {
    return this._selectedMonth;
  }

  @Input() set selectedFinancialYear(year: string) {
    if (!year) {
      return;
    }
    this._selectedFinancialYear = year;
    this.onShowData('Year');
    this.form.patchValue({ year }, { emitEvent: false, onlySelf: true });
  }
  get selectedFinancialYear(): string {
    return this._selectedFinancialYear;
  }

  @Input() banksList: Array<Partial<SelectItem>> = [];
  @Input() roleList: Array<SelectItem> = [];
  @Input() accountsList: Array<{ account_no: string; bank_code: string; ccy: string }> = [];
  @Input() tradeTransactionList: Array<SelectItem> = [];
  @Input() originatorIds: Array<SelectItem> = [];
  @Input() ledgers: Array<SelectItem> = [];
  @Input() dateRange: any;
  @Input() selectedRecordPerPage: any;
  @Input() label: string = '';
  @Input() showNewButton: boolean = false;
  @Input() filtersMultiple: boolean = true;
  @Input() rangeSelector: boolean = true;
  @Input() noDateAndSearch: boolean = false;
  @Input() noSearch: boolean = false;
  @Input() isShowPerPage: boolean = true;
  @Input() isShowActionButtonGroup: boolean = true;
  disabled: boolean;

  @Input() set loading(val: boolean) {
    this.disabled = val;
    if (val) {
      this.form.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.form.enable({ onlySelf: true, emitEvent: false });
    }
  }

  @Input() set savedFilterModel(value: Partial<any>) {
    if (value) {
      this.form.patchValue(value, { emitEvent: false, onlySelf: true });
    }
  }

  @Output() saveFilterData = new EventEmitter<any>();
  @Output() refreshPage = new EventEmitter<void>();
  @Output() createNew = new EventEmitter<string>();

  @Output() formChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() export: EventEmitter<any> = new EventEmitter<any>();
  @Output() print: EventEmitter<any> = new EventEmitter<any>();
  financialList: Array<SelectItem> = generateYearRange(2010);
  monthList: Array<{ id: string; date: string }> = generateDateRange();

  constructor(
    private fb: FormBuilder,
    public tradeSharedService: TradeSharedService,
    public entitiesSharedService: EntitiesSharedService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.form.valueChanges.pipe(debounceTime(700)).subscribe((value) => {
        const filter = {};
        const newValue = {
          ...value,
          startDate: value?.startDate ? moment(value.startDate[0]).format('YYYY-MM-DD') : null,
          endDate: value?.startDate ? moment(value?.startDate[1]).format('YYYY-MM-DD') : null,
        };
        for (const valueKey in newValue) {
          (filter as any)[valueKey] = newValue[valueKey];
        }
        this.formChange.emit(filter);
      }),
    );
    this.subscriptions.add(
      this.tradeSharedService.getEtrType().subscribe((etrType) => {
        const typeControl = this.form.controls['type'];
        typeControl.setValue(etrType, { onlySelf: true, emitEvent: false });
      }),
    );
    this.subscriptions.add(
      this.entitiesSharedService.getRoleType().subscribe((roleType) => {
        const typeControl = this.form.controls['role'];
        typeControl.setValue(roleType, { onlySelf: true, emitEvent: false });
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.filtersMultiple && this.form.controls['bank'] && this.form.controls['account'] && this.accountsList?.length > 0) {
      const bankControl = this.form.controls['bank'];
      bankControl.disable({ onlySelf: true, emitEvent: false });
      const bankCode = this.accountsList.find((val) => val.account_no === this.form.controls['account'].value)?.bank_code;
      bankControl.setValue(bankCode, { onlySelf: true, emitEvent: false });

      this.subscriptions.add(
        this.form.controls['account'].valueChanges.subscribe((value) => {
          const bankCode = this.accountsList.find((val) => val.account_no === value)?.bank_code;
          bankControl.setValue(bankCode, { onlySelf: true, emitEvent: false });
        }),
      );
    }
  }

  onShowData(range: string) {
    this.currentFilter = range;
    this.form.patchValue(
      {
        month: '',
        year: '',
        startDate: '',
        endDate: '',
      },
      { emitEvent: range === 'All' },
    );
  }

  openAddNewModal(type: string): void {
    this.createNew.emit(type);
  }

  resetFormGroup(): void {
    this.form.reset({ perPage: this.form.value.perPage, account: this._defaultAccount, clutch: this._defaultClutch, bank: this._defaultBank });
    this.noDateAndSearch || this.onShowData('All');
    this.refreshPage.emit();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
