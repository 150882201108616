import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class EditUploadsService {
  investmentForm: FormGroup;
  transactionsForm: FormGroup;
  transactionsFormMultiple: FormGroup;
  etrForm: FormGroup;
  etrFormMultiple: FormGroup;
  organisationsForm: FormGroup;
  assetForm: FormGroup;
  getquestion: any;
  type: any;
  transaction: any;
  ccy: any;
  type2: any;
  oldtransaction: any;
  oldinvestment: any;
  financialYear: any;
  Year: any;
  bank_date: Date | undefined;

  constructor(private formBuilder: FormBuilder) {
    this.investmentForm = this.formBuilder.group({
      Id: [null],
      Bank_Date: [null],
      Transaction_Details: [null],
      Type: [''],
      Payment_Out: [''],
      Receipt_In: [''],
      Intermediary_ID: ['', [Validators.pattern('^[0-9]*$')]],
      Intermediary: [''],
      Investor_ID: ['', [Validators.pattern('^[0-9]*$')]],
      Investor_name: [''],
      Transaction: [''],
      Ccy: [''],
      Face_Value: ['', [Validators.pattern('^[0-9]+(.[0-9]+)?$')]],
      Date: [null],
      Buy_Rate: ['', [Validators.required, Validators.pattern('^[0-9]*$')]], // Add your custom validator here
      Commission: [''], // Add your custom validator here
      Months: ['', [Validators.pattern('^[0-9]*$')]],
      Type2: [''],
      Comments: [null],
      Old_Transaction: [''],
      Old_Investment: [''],
    });

    this.transactionsForm = this.formBuilder.group({
      id: [null],
      date: [null],
      valueDate: [null],
      transactionDetails: [null],
      transactionType: [null],
      paymentAmount: ['', [Validators.pattern(/^\d*\.?\d+$/), Validators.maxLength(12)]],
      receiptAmount: ['', [Validators.pattern(/^\d*\.?\d+$/), Validators.maxLength(12)]],
      ledgerBalance: [''],
      ccy: [''],
      clearedStatus: [''],
      chequeNumber: ['', [Validators.pattern(/^\d*\.?\d+$/), Validators.maxLength(10)]],
      customerReference: ['', [Validators.maxLength(17)]],
      bankReference: ['', [Validators.maxLength(17)]],
      account: [''],
      name: [''],
      ledgerOwner: [null],
      mode: [''],
      entityRole: [''],
      reaccount: [''],
      entityId: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(17)]],
      entityName: ['', [Validators.maxLength(17)]],
      relatedId: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(17)]],
      relatedName: ['', [Validators.maxLength(17)]],
      comments: [''],
      notes: [''],
      rx: [''],
    });

    this.transactionsFormMultiple = this.formBuilder.group({
      id: [null], // array of ids
      mode: [''],
      reaccount: [''],
      entityId: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(17)]],
      entityName: ['', [Validators.maxLength(17)]],
      relatedId: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(17)]],
      relatedName: ['', [Validators.maxLength(17)]],
      comments: [''],
    });

    this.etrForm = this.formBuilder.group({
      id: [null],
      originatorId: [''],
      credebtorId: [''],
      debtorId: [''],
      classification: [''],
      deposit: [''],
      issuedDate: [''],
      tradeId: [''],
      ref: [''],
      postedDate: [''],
      tradedDate: [''],
      edso: [''],
      ccy: [''],
      faceValue: [''],
      purchasedValue: [''],
      sellRate: [''],
      sellType: [''],
      disable180Days: [''],
      ldcPremium: [''],
      agentPremium: [''],
      tradedFx: [''],
      tradeCommission: [''],
      settledDate: [''],
      settledFx: [''],
      closedDate: [''],
      closedFx: [''],
      type: [''],
      quantity: [''],
      months: [''],
      unitPrice: [''],
      eot: [''],
      vatIncluded: [''],
      vatOnPurchases: [''],
      idtkoi: [''],
      creditorId: [''],
      comments: [''],
      notes: [''],
      resumedAt: [''],
      pausedAt: [''],
      ledger: [''],
      batch: [''],
      leaseeId: [''],
    });

    this.organisationsForm = this.formBuilder.group({
      entityID: [null],
      role: [null],
      entityName: [null],
      phoneNumber: [null],
      mobileNumber: [null],
      email: [null],
      address1: [null],
      address2: [null],
      area: [null],
      city: [null],
      state: [null],
      country: [null],
      postCode: [null],
      prefix: [null],
      accountName: [null],
      sortCode: [null],
      swiftBIC: [null],
      iBAN: [null],
      accountNumber: [null],
    });

    this.assetForm = this.formBuilder.group({
      id: [''],
      assetId: [''],
      name: [''],
      batch: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      ccy: ['', [Validators.required]],
      unitPrice: [''],
      classification: ['', [Validators.required]],
      description: [''],
      serialNumber: ['', [Validators.required]],
      repairMaintenance: [''],
      eot: [''],
      vatIncluded: ['', [Validators.required]],
      vatOnPurchases: ['', [Validators.required]],
    });

    this.getquestion = {
      Bank_Date: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Transaction_Details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Type: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Payment_Out: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Receipt_In: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Intermediary_ID: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Intermediary: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Investor_ID: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Investor_name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Transaction: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Ccy: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Face_Value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Date: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Buy_Rate: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Commission: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Months: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Type2: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Comments: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Old_Transaction: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
      Old_Investment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    };

    this.type = [
      { id: '', name: '------- Select -------' },
      { id: 'Transfer', name: 'Transfer' },
      { id: 'Cheques', name: 'Cheques' },
      { id: 'Remittance', name: 'Remittance' },
      { id: 'Miscellaneous', name: 'Miscellaneous' },
    ];
    this.transaction = [
      { id: '', name: '--- Select ---' },
      { id: 'Receipt', name: 'Receipt' },
      { id: 'Compound', name: 'Compound' },
      { id: 'Redemption', name: 'Redemption' },
    ];
    this.ccy = [
      { id: 'EUR', name: 'EUR' },
      { id: 'GBP', name: 'GBP' },
      { id: 'USD', name: 'USD' },
    ];
    this.type2 = [
      { id: 'Demand', name: 'Demand' },
      { id: 'Term', name: 'Term' },
      { id: 'Fixed', name: 'Fixed' },
    ];
    this.oldtransaction = [
      { id: 'RPA Receipt', name: 'RPA Receipt' },
      { id: 'Compound', name: 'Compound' },
      { id: 'Addition', name: 'Addition' },
      { id: 'Full Redemption', name: 'Full Redemption' },
      { id: 'Coupon', name: 'Coupon' },
      { id: 'Renewal', name: 'Renewal' },
      { id: 'Partial Coupon', name: 'Partial Coupon' },
    ];
    this.oldinvestment = [
      { id: 'Investabill', name: 'Investabill' },
      { id: 'Leasabill', name: 'Leasabill' },
    ];

    this.financialYear = [
      { id: '', name: '------- Select Year -------' },
      { id: '2024', name: 'Jan 2024 To Dec 2024' },
      { id: '2023', name: 'Jan 2023 To Dec 2023' },
      { id: '2022', name: 'Jan 2022 To Dec 2022' },
      { id: '2021', name: 'Jan 2021 To Dec 2021' },
      { id: '2020', name: 'Jan 2020 To Dec 2020' },
      { id: '2019', name: 'Jan 2019 To Dec 2019' },
      { id: '2018', name: 'Jan 2018 To Dec 2018' },
      { id: '2017', name: 'Jan 2017 To Dec 2017' },
      { id: '2016', name: 'Jan 2016 To Dec 2016' },
      { id: '2015', name: 'Jan 2015 To Dec 2015' },
      { id: '2014', name: 'Jan 2014 To Dec 2014' },
      { id: '2013', name: 'Jan 2013 To Dec 2013' },
    ];
    //Jan 2023 to Dec 2023
    this.Year = [
      { id: '', name: 'Select Year' },
      { id: '2024', name: '2024' },
      { id: '2023', name: '2023' },
      { id: '2022', name: '2022' },
      { id: '2021', name: '2021' },
      { id: '2020', name: '2020' },
      { id: '2019', name: '2019' },
      { id: '2018', name: '2018' },
      { id: '2017', name: '2017' },
      { id: '2016', name: '2016' },
      { id: '2015', name: '2015' },
      { id: '2014', name: '2014' },
      { id: '2013', name: '2013' },
      { id: '2012', name: '2012' },
      { id: '2011', name: '2011' },
      { id: '2010', name: '2010' },
      //2010 to  2024
    ];
  }

  initializeForm() {}

  validateInput(event: any): void {
    const input = event.target;
    const currentValue = input.value;

    // Remove any non-numeric, non-comma, non-dot characters
    const newValue = currentValue.replace(/[^0-9,.]/g, '');

    // Update the input value
    if (newValue !== currentValue) {
      input.value = newValue;
    }
  }

  setTransactionsFormMultiple(selectedRows: any[]): FormGroup {
    const values = {
      id: selectedRows.map((val: any) => val.id),
      mode: selectedRows.every((val: any) => val.mode === selectedRows[0].mode) ? selectedRows[0].mode || '' : null,
      reaccount: selectedRows.every((val: any) => val.reaccount === selectedRows[0].reaccount) ? selectedRows[0].reaccount || '' : null,
      entityId: selectedRows.every((val: any) => val.entityId === selectedRows[0].entityId) ? selectedRows[0].entityId || '' : null,
      entityName: selectedRows.every((val: any) => val.entityName === selectedRows[0].entityName) ? selectedRows[0].entityName || '' : null,
      relatedId: selectedRows.every((val: any) => val.relatedId === selectedRows[0].relatedId) ? selectedRows[0].relatedId || '' : null,
      relatedName: selectedRows.every((val: any) => val.relatedName === selectedRows[0].relatedName) ? selectedRows[0].relatedName || '' : null,
      comments: selectedRows.every((val: any) => val.comments === selectedRows[0].comments) ? selectedRows[0].comments || '' : null,
    };
    const formBuild: { [key: string]: any[] } = {};
    Object.keys(values).forEach((key) => {
      if (values.hasOwnProperty(key) && ((values as any)[key] || (values as any)[key] === '')) {
        formBuild[key as string] = [(values as any)[key]];
      }
    });
    this.transactionsFormMultiple = this.formBuilder.group(formBuild);
    return this.transactionsFormMultiple;
  }

  setEtrFormMultiple(
    values: Partial<{
      id: number[]; // array of ids
      mode: string;
      transactionIdentifyDate: Date | { year: number; day: number; month: number } | string | null | undefined;
    }>,
  ): FormGroup {
    const formBuild: { [key: string]: any[] } = {};
    Object.keys(values).forEach((key) => {
      if (values.hasOwnProperty(key) && ((values as any)[key] || (values as any)[key] === '')) {
        formBuild[key as string] = [(values as any)[key]];
      }
    });
    this.etrFormMultiple = this.formBuilder.group(formBuild);
    return this.etrFormMultiple;
  }
}
