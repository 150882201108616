<div class="row p-1">
  <div class="col-lg-12 col-xl-12">
    <div class="card w-100">
      <div class="card">
        <form
          *ngIf="investmentForm"
          [formGroup]="investmentForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="row">
            <div class="width-236">
              <div class="mt-3">
                <label for="intermediaryId" class="form-title">
                  <span class="required">*</span>Intermediary
                </label>
                <ng-select class="custom"
                           id="intermediaryId"
                           [style.flex]="1"
                           [items]="intermediaryIdList"
                           bindLabel="name"
                           bindValue="id"
                           placeholder="All"
                           formControlName="intermediaryId"
                           (change)="handleIntermediaryChange($event)">
                </ng-select>
              </div>
              <app-form-error-component [control]="investmentForm.controls['intermediaryId']" />
            </div>
            <div class="width-236">
              <div class="mt-3">
                <label for="investorId" class="form-title">
                  <span class="required">*</span> Investor
                </label>
              </div>
              <ng-select class="custom"
                         id="investorId"
                         [style.flex]="1"
                         [items]="investorGeneratedList"
                         bindLabel="name"
                         bindValue="id"
                         placeholder="All"
                         formControlName="investorId"
                         [searchFn]="ngSelectCustomSearch"
                         (change)="investorChange($event)"
              >
              </ng-select>
              <app-form-error-component [control]="investmentForm.controls['investorId']" />
            </div>

            <div class="width-112">
              <div class="mt-3">
                <label for="transactionType" class="form-title">
                  <span class="required">*</span> Transaction
                </label>
                <select
                  class="custom-form-select border-radius-100"
                  id="transactionType"
                  formControlName="transactionType"
                >
                  <option *ngFor="let tr of transactionType" [value]="tr.id">
                    {{ tr.name }}
                  </option>
                </select>
              </div>
              <app-form-error-component [control]="investmentForm.controls['transactionType']" />
            </div>

            <ng-container *ngIf="investmentForm.get('transactionType')?.value">
              <div class="width-64">
                <div class="mt-3">
                  <label for="type" class="form-title">
                    <span class="required">*</span> Type
                  </label>
                  <select
                    class="custom-form-select border-radius-100"
                    id="type"
                    formControlName="type"
                  >
                    <option *ngFor="let tt of type" [value]="tt.id">
                      {{ tt.name }}
                    </option>
                  </select>
                </div>
                <app-form-error-component [control]="investmentForm.controls['type']" />
              </div>

              <div class="width-120">
                <div class="mt-3">
                  <label for="date" class="form-title">
                    <span class="required">*</span> Date
                  </label>
                  <div class="input-group">
                    <input
                      id="date"
                      class="form-control"
                      placeholder="YYYY-MM-DD"
                      name="d3"
                      formControlName="date"
                      ngbDatepicker
                      #d3="ngbDatepicker"
                      [maxDate]="maxDate"
                    />
                    <button
                      class="btn btn-outline-secondary btn-calendar-icon"
                      (click)="d3.toggle()"
                      type="button"
                    >
                      <img src="./assets/images/icon/calendar-icon.svg" />
                    </button>
                  </div>
                </div>
                <app-form-error-component [control]="investmentForm.controls['date']" />
              </div>
              <div
                *ngIf="investmentForm.value.transactionType === 'Receipt' || investmentForm.value.transactionType === 'Renewal'"
                class="width-57">
                <div class="mt-3">
                  <label for="ccy" class="form-title">
                    <span class="required">*</span> Ccy
                  </label>
                  <select
                    class="custom-form-select border-radius-100"
                    id="ccy"
                    formControlName="ccy"
                  >
                    <option *ngFor="let cc of ccy" [value]="cc.id">
                      {{ cc.name }}
                    </option>
                  </select>
                </div>
                <app-form-error-component [control]="investmentForm.controls['ccy']" />
              </div>
              <div *ngIf="investmentForm.value.transactionType !== 'Renewal'" class="width-85">
                <div class="mt-3">
                  <label for="bankPayment" class="form-title">
                    <span class="required">*</span>Amount
                  </label>
                  <input
                    [onlyNumber]="false"
                    id="bankPayment"
                    type="text"
                    class="form-control ft-width inputNumberText"
                    placeholder=""
                    formControlName="faceValue"
                    maxlength="12"
                    (focus)="handleFocusFaceValue()"
                    (focusout)="handleFocusOutFaceValue()"
                  />
                </div>
                <app-form-error-component [control]="investmentForm.controls['faceValue']" />
              </div>
              <div
                *ngIf="investmentForm.value.transactionType !== 'Adjustment' && investmentForm.value.transactionType !== 'Renewal'"
                class="width-71">
                <div class="mt-3">
                  <label for="fxRate" class="form-title"> Fx Rate</label>
                  <div class="input-container justify-content-end">
                    <span style="margin-right: 5px" id="fxRate">{{ investmentForm.get('fxRate')?.value }}</span>
                  </div>
                </div>
                <app-form-error-component [control]="investmentForm.controls['fxRate']" />
              </div>
              <div
                *ngIf="investmentForm.value.transactionType !== 'Adjustment' && investmentForm.value.transactionType !== 'Renewal'"
                class="width-110">
                <div class="mt-3">
                  <label for="eurAmount" class="form-title"> EUR Amount</label>
                  <div class="input-container justify-content-end">
                    <span style="margin-right: 5px" id="eurAmount">{{ investmentForm.get('eurAmount')?.value }}</span>
                  </div>
                </div>
                <app-form-error-component [control]="investmentForm.controls['eurAmount']" />
              </div>

              <div class="width-53"
                   *ngIf="investmentForm.get('transactionType')?.value === 'Receipt' || investmentForm.value.transactionType === 'Renewal'">
                <div class="mt-3">
                  <label for="months" class="form-title text-nowrap">
                    <span class="required">*</span> Months
                  </label>
                  <input
                    id="months"
                    onlyNumber
                    type="text"
                    class="form-control ft-width inputNumberText"
                    placeholder=""
                    formControlName="months"
                    maxlength="2"

                  />
                </div>
                <app-form-error-component [control]="investmentForm.controls['months']" />
              </div>
              <div class="width-100"
                   *ngIf="investmentForm.get('transactionType')?.value === 'Receipt' || investmentForm.value.transactionType === 'Renewal'">
                <div class="mt-3">
                  <label for="ledger" class="form-title"><span class="required">*</span>Ledger</label>
                  <select
                    class="custom-form-select border-radius-100"
                    id="ledger"
                    formControlName="ledgerId"
                  >
                    <option *ngFor="let ledger of ledgerData" [value]="ledger.id">
                      {{ ledger.name }}
                    </option>
                  </select>
                </div>
                <app-form-error-component [control]="investmentForm.controls['ledgerId']" />
              </div>
              <div class="width-68">
                <div class="mt-3">
                  <label for="product" class="form-title text-nowrap">
                    <span class="required">*</span>Product
                  </label>
                  <select
                    class="custom-form-select border-radius-100"
                    id="product"
                    formControlName="product"
                  >
                    <option *ngFor="let prod of product" [value]="prod.id">
                      {{ prod.name }}
                    </option>
                  </select>
                </div>
                <app-form-error-component [control]="investmentForm.controls['product']" />
              </div>
              <ng-container
                *ngIf="investmentForm.get('transactionType')?.value === 'Receipt' || investmentForm.value.transactionType === 'Renewal'">
                <div class="width-86">
                  <div class="mt-3">
                    <label for="buyRate" class="form-title text-nowrap"><span class="required">*</span>Buy Rate</label>
                    <div class="input-container">
                      <input
                        id="buyRate"
                        type="text"
                        [onlyNumber]="false"
                        class="form-control ft-width inputNumberText"
                        formControlName="buyRate"
                        maxlength="10"
                        (blur)="formatSellRate()"
                      />
                      <span>%</span>
                    </div>
                  </div>
                  <app-form-error-component [control]="investmentForm.controls['buyRate']" />
                </div>

                <div class="width-86 input-commission">
                  <div class="mt-3">
                    <label for="commission" class="form-title text-nowrap"><span class="required">*</span>
                      Commission</label>
                    <div class="input-container">
                      <input
                        id="commission"
                        type="text"
                        [onlyNumber]="false"
                        class="form-control ft-width inputNumberText"
                        placeholder=""
                        formControlName="commission"
                        maxlength="10"
                        (blur)="formatCommission()"
                      />
                      <span>%</span>
                    </div>
                  </div>
                  <app-form-error-component [control]="investmentForm.controls['commission']" />
                </div>
              </ng-container>
              <ng-container>
                <div class="mb-3 field-group width-140">
                  <div class="mt-3">
                    <label><span class="required">*</span>Saving :</label>
                    <div class="mt-1 d-flex align-items-center">
                      <div *ngFor="let radiobutton of savings; let i = index"
                           class="radio-option d-flex align-items-center  me-2 mb-0">
                        <input type="radio" class="me-1" name="options2" id="option{{i}}"
                               (click)="onSavingChange(radiobutton)"
                               [checked]="radiobutton === savingModel.option" class="clickable-cursor">
                        <label for="option{{i}}" class="clickable-cursor ms-1">{{ radiobutton }}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="width-100" *ngIf="savingModel.option === 'Pension'">
                  <div class="mt-3">
                    <label for="trustee" class="form-title text-nowrap"><span class="required">*</span>Trustee
                      <!--<span class="tooltip-ng" placement="bottom" ngbTooltip="{{ tooltipData.trustee }}">
                        <i class="bi bi-question-circle"></i>
                      </span>-->
                    </label>
                    <select
                      class="custom-form-select border-radius-100"
                      id="trustee"
                      formControlName="trusteeId"
                    >
                      <option *ngFor="let trustee of trusteeList" [value]="trustee.id">
                        {{ trustee.name }}
                      </option>
                    </select>
                  </div>
                  <app-form-error-component [control]="investmentForm.controls['trusteeId']" />
                </div>
              </ng-container>

              <div class="col-md-12">
                <div class="mt-3 comments">
                  <label for="comments" class="form-title"> Transaction Details
                    <span
                      class="tooltip-ng ms-0"
                      placement="bottom"
                      ngbTooltip="{{ tooltipData.comments }}">
                    <i class="bi bi-question-circle"></i>
                  </span>
                  </label>
                  <textarea
                    type="text"
                    id="comments"
                    class="form-control"
                    placeholder="Write here about  transaction..."
                    formControlName="comments"
                    row="8"
                    maxlength="512">
                </textarea>
                </div>
                <app-form-error-component [control]="investmentForm.controls['Transaction_Details']" />
              </div>
            </ng-container>
            <div class="col-12 bottom-fixed">
              <hr />
              <div class="d-flex justify-content-center mt-3">
                <button [disabled]="investmentForm.invalid" type="submit"
                        class="btn btn-light me-3 btn-custom-footer">
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-light btn-custom-footer"
                  data-dismiss="modal"
                  (click)="closeModal()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

