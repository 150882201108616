import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgIf } from '@angular/common';
import { UploadsComponent } from 'src/app/uploads/uploads/uploads.component';
import { SelectItem } from '../interfaces/select-item.interface';

//declare var $: any;
@Component({
  selector: 'app-ngbd-modal-content',
  standalone: true,
  template: `
    <div class="modal-header" role="dialog" aria-modal="true">
      <h4 class="modal-title">Hi there!</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <app-uploads></app-uploads>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NgbdModalContentComponent {
  activeModal = inject(NgbActiveModal);

  @Input() name: string | undefined;
}
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports:[RouterModule, CommonModule, NgIf],
  templateUrl: './sidebar.component.html',
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  isClassAdded =false;
  public sidebarnavItems:RouteInfo[]=[];
  uploadTypes: SelectItem[] = [
    { id: '1', name: 'Investment' },
    { id: '2', name: 'ETR' },
    { id: '3', name: 'Bank' },
  ];
  // this is for the open close
  addExpandClass(element: string) {
    if (element === this.showMenu) {
      this.showMenu = '0';
      this.showSubMenu = '0';
    } else {
      this.showMenu = element;
      this.showSubMenu = '0';
    }
  }

  addExpandSubClass(element: string) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    }
    else if (element === 'Investment' || element === 'ETR' || element === 'Bank') {
      this.callModal(element)
    }
    else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private up: UploadsComponent
  ) {}
  @ViewChild('projctModal') projctModal: any;
  openModal() {
    this.projctModal.show();
  }

  callModal(uploadType: string) {
    const selectedType = this.uploadTypes.find(type => type.name === uploadType);
    const uploadTypeId = selectedType ? selectedType.id : '0';
    const modalRef = this.modalService.open(UploadsComponent, {
      backdrop: true,
      windowClass: 'data-entry-custom-model' 
    });
    modalRef.componentInstance.uploadTypeId = Number(uploadTypeId);
  }

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }

}

