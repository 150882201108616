import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BanksQueryParams } from '../shared/interfaces/banks-query-params';
import { BankItem, BankResponse, Summary } from '../shared/interfaces/bank-response.interface';
import { DistinctValuesResponse } from '../shared/interfaces/distinct-values-response.interface';
import { TransactionBulkUpdateInterface } from '../shared/interfaces/transaction-bulk-update.interface';
import { API_URL } from '../app.module';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    @Inject(API_URL) public baseUrl: string,
    public http: HttpClient,
  ) {}

  postData(serviceName: string, data: any): Observable<any> {
    // Assuming 'data' is the raw data you want to send
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the appropriate content type
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
    const baseUrl = this.baseUrl + serviceName;
    return this.http.post(baseUrl, data, { headers });
  }

  getData(serviceName: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the appropriate content type
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
    const baseUrl = this.baseUrl + serviceName;
    return this.http.get(baseUrl, { headers });
  }

  getBankData(params: Partial<BanksQueryParams>): Observable<BankResponse> {
    const baseUrl = this.baseUrl + 'banks';
    return this.http.get<BankResponse>(baseUrl, {
      params: this.queryCleaner(params),
    });
  }

  getBankSummary(params: Partial<BanksQueryParams>): Observable<Summary> {
    return this.http.get<Summary>(`${this.baseUrl}banks/summary`, {
      params: this.queryCleaner(params),
    });
  }

  getFilterDistinctValues(): Observable<DistinctValuesResponse> {
    return this.http.get<DistinctValuesResponse>(`${this.baseUrl}entity/filter-distinct-values`);
  }

  getBankFilterDistinctValues(): Observable<DistinctValuesResponse> {
    return this.http.get<DistinctValuesResponse>(`${this.baseUrl}banks/filter-distinct-values`);
  }

  deleteBankData(id: any) {
    const baseUrl = this.baseUrl + 'banks/' + id;
    return this.http.delete(baseUrl);
  }

  bulkDeleteBankData(ids: number[]) {
    const baseUrl = `${this.baseUrl}banks/bulk-delete`;
    return this.http.delete(baseUrl, { body: { ids } });
  }

  updateBankData(id: any, data: any): Observable<BankItem> {
    const baseUrl = this.baseUrl + 'banks/' + id;
    return this.http.patch<BankItem>(baseUrl, this.queryCleaner(data));
  }

  bulkUpdateTransactions(body: TransactionBulkUpdateInterface): Observable<any> {
    const dto = this.queryCleanerBulk(body.dto);
    return this.http.patch(`${this.baseUrl}banks/bulk-update`, {
      ids: body.ids,
      dto,
    });
  }

  getCurrencyWithBank() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the appropriate content type
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
    const baseUrl = this.baseUrl + 'banks/currencies-banks-accounts';
    return this.http.get(baseUrl, { headers });
  }
  getLedgerOwner() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the appropriate content type
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
    const baseUrl = this.baseUrl + 'banks/ledger-owners';
    return this.http.get(baseUrl, { headers });
  }
  getTransactionTypes() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the appropriate content type
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
    const baseUrl = this.baseUrl + 'banks/transaction-types';
    return this.http.get(baseUrl, { headers });
  }
  getStatus() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the appropriate content type
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
    const baseUrl = this.baseUrl + 'banks/cleared-statuses';
    return this.http.get(baseUrl, { headers });
  }
  updateData(serviceName: string, id: any, data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the appropriate content type
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
    const baseUrl = this.baseUrl + serviceName;
    // Include headers in the request
    const options = { headers: headers };

    return this.http.put(`${baseUrl}/${id}`, data, options);
  }

  updateDataV2(serviceName: string, id: any, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the appropriate content type
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
    const baseUrl = this.baseUrl + serviceName;
    // Include headers in the request
    const options = { headers: headers };

    return this.http.patch(`${baseUrl}/${id}`, data, options);
  }

  deleteData(serviceName: string, id: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Set the appropriate content type
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    });
    const baseUrl = this.baseUrl + serviceName;
    // Include headers in the request
    const options = { headers: headers };

    return this.http.delete(`${baseUrl}/${id}`, options);
  }

  upload(file: any, type: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    console.log(type);

    let uploadType = 'upload';
    let baseUrl = this.baseUrl + 'csv/' + uploadType;

    if (type == 'investment') {
      uploadType = 'upload';
      baseUrl = this.baseUrl + 'investments/import';
    } else if (type == 'bank') {
      baseUrl = this.baseUrl + 'banks/import';
      uploadType = 'banksUpload';
    } else if (type == 'ETR') {
      uploadType = 'etrUpload';
      baseUrl = this.baseUrl + 'etr/import';
    }
    return this.http.post(baseUrl, formData);
  }

  uploadValidate(file: any, type: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const uploadType = 'upload';
    let baseUrl = this.baseUrl + 'csv/' + uploadType;
    if (type == 'investment') {
      baseUrl = this.baseUrl + 'investments/validate-import';
    } else if (type == 'bank') {
      baseUrl = this.baseUrl + 'banks/validate-import';
    } else if (type == 'ETR') {
      baseUrl = this.baseUrl + 'etr/validate-import';
    }
    return this.http.post(baseUrl, formData);
  }

  queryCleaner<T>(params: Partial<T>): { [key: string]: any } {
    for (const paramsKey in params) {
      if (!params[paramsKey as keyof T] && params[paramsKey as keyof T] !== 0) {
        delete (params as any)[paramsKey];
      }
    }
    return params;
  }

  queryCleanerBulk<T>(params: Partial<T>): { [key: string]: any } {
    for (const paramsKey in params) {
      if (!params[paramsKey as keyof T] || params[paramsKey as keyof T] === '~') {
        (params as any)[paramsKey] = null;
      }
    }
    return params;
  }
}
