import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef, DomLayoutType, ProcessCellForExportParams, SideBarDef } from '@ag-grid-community/core';
import { CustomDateComponent } from './date-filter.component';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-grid-container',
  templateUrl: './grid-container.component.html',
  styleUrls: ['./grid-container.component.scss'],
})
export class GridContainerComponent {
  @Input() gridRowData: any[];

  private _components: any = {
    agDateInput: CustomDateComponent,
  };

  get components() {
    return this._components;
  }

  @Input() set components(obj: any) {
    this._components = { ...obj, ...this._components };
  }

  @Input() gridColDefs: ColDef[];
  @Input() gridDefaultColDef: ColDef;
  @Input() gridOptions?: GridOptions<any> | undefined;
  @Input() hasPagination = false;
  @Input() gridPaginationPageSize = 100;
  @Input() sideBar: SideBarDef | string | string[] | boolean | null;
  @Input() domLayout: DomLayoutType;
  @Input() hasMasterDetail = true;
  @Input() keepDetailRows = true;
  @Input() keepDetailRowsCount = 2;
  @Input() detailCellRendererParams: any;
  @Input() rowHeight = 20;
  @Input() isGridDraggable: boolean;
  @Input() editType: 'fullRow';
  @Input() rowSelection: 'multiple' | 'single' = 'multiple';
  @Input() isSuppressMoveWhenRowDragging: boolean;
  @Input() classNames: string;
  @Input() isPinnedRowHide = false;
  @Output() gridReady = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() sortChanged = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() cellClicked = new EventEmitter<any>();
  @Output() cellValueChanged = new EventEmitter<any>();
  @Output() rowValueChanged = new EventEmitter<any>();
  @Output() rowDragEnd = new EventEmitter<any>();
  @Output() rowDragEnter = new EventEmitter<any>();
  @Output() rowDragMove = new EventEmitter<any>();
  @Output() columnVisible = new EventEmitter<any>();
  @Output() rowDoubleClicked = new EventEmitter<any>();
  @Output() rowSelected = new EventEmitter<any>();
  getContextMenuItems = (params: any) => {
    if (this.isPinnedRowHide && params.node.rowPinned === 'bottom') {
      return [];
    }
    return ['copy', 'copyWithHeaders'];
  };
  getRowId = (params: any) => params.data.id;

  processCellForClipboard: ((params: ProcessCellForExportParams<any>) => any) | undefined = (params) => {
    if (typeof params.value === 'string') {
      return params.value?.replace(/\n/g, '').replace(/\s+/g, ' ').trim();
    } else {
      return params.value;
    }
  };
}
