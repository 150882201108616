import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { errorMessage } from '../shared/helpers/messages-popup';
import { EXCLUDED_URLS } from '../shared/constants/excluded-urls.const';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMsg = EXCLUDED_URLS.find((url: { url: string; error: string }) => (error.url as string).includes(url.url))?.error || error?.error?.details || error?.error?.message || 'Error';
        errorMessage(errorMsg);
        return throwError(() => errorMsg);
      }),
    );
  }
}
